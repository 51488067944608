import React, { useState } from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import LoadingScreen from '../../../LoadingScreen';

import SimpleModal from './SimpleModal';

import DatePicker from 'react-datepicker';
import '../../../Datepicker.css';
import { format } from 'date-fns';

const customModalStyles = {
  content: {
    width: '80%', 
    //height: '90%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '1%',
  },
};

const AddPaymentToInstitutionModal = ({ isOpen, onRequestClose, institution }) => {
  //const [paymentId, setPaymentId] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [year, setYear] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [tellerNumber, setTellerNumber] = useState('');
  const [comment, setComment] = useState('');


  //
  const currentYear = new Date().getFullYear();
  const pastYears = Array.from({ length: 7 }, (_, i) => currentYear - i);
  //const pastYears = Array.from({ length: 5 }, (_, i) => currentYear - i).filter(year => year !== currentYear); // Exclude current year
  
  //const futureYears = Array.from({ length: 5 }, (_, i) => currentYear + i);
  // const years = [...pastYears, ...futureYears];
  const years = [...pastYears];
  //


  

  const [registrationStatus, setRegistrationStatus] = useState(null);
 const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);

  const [registrationError, setRegistrationErrorMessage] = useState('');

  const [isNetworkLoading, setIsNetworkLoading] = useState(false);


  // Function to close the modal
  const closeSimpleModal = () => {
    setIsSimpleModalOpen(false);
    clearFields();
    if (registrationStatus == "Registration Successful") {
      //navigate("/");
      onRequestClose();
    }
    
  };

  const clearFields = () => {
    setAmountPaid('');
    setYear('');
    setPaymentDate('');  
    setTellerNumber('');
    setComment('');
  
    setRegistrationStatus(null);
    //setIsSimpleModalOpen(false);
  
    //setRegistrationErrorMessage('');
  }


  const hitAddPaymentToInsitutionEndpoint = async () => {
    //onAddInstitution(institutionName);
    //setInstitutionName('');

    setRegistrationErrorMessage('');
    setIsNetworkLoading(true);

    // alert(
    //   'amountPaid: ' + parseFloat(amountPaid) + "\n" +
    //   'year: ' + year + "\n" +
    //   'paymentDate: ' + paymentDate + "\n" +
    //   'tellerNumber: ' + tellerNumber + "\n" +
    //   'comment: ' + comment + "\n\n" +
    //   'institutionId: ' + institution.id + "\n" + "\n"  +
    //   "https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/payments"
    // );

    if (
      amountPaid  == ''  ||
      year == '' ||
      paymentDate == '' ||
      tellerNumber == '' ||
      comment == ''
      ) {
      alert("Please enter payment details");
      setIsNetworkLoading(false);
      return;
    }

    //alert("!");
    let formattedDate = paymentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replace(/\//g, '-');    
    let pd = formattedDate;


    try {
      const token = localStorage.getItem('token')
      //alert(token);

      const formData = new FormData();
      formData.append('amountPaid', amountPaid);
      formData.append('year', year);
      formData.append('paymentDate', pd);//paymentDate);
      formData.append('tellerNumber', tellerNumber);
      formData.append('comment', comment);

      // Append the actual file to the formData

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/payments", formData, {
        headers: {
          //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        //alert("ok");
        setRegistrationStatus("Registration Successful");
        setIsSimpleModalOpen(true);
        // //console.log('Registration successful:' + response.data.message);

        // //alert("oka");
        //clearFields();

        // // Close the modal
        // alert("Registration Successful");
        // onRequestClose();
        // //onAddPayment();

        // You can also redirect the user to a confirmation page or do any other actions.
      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Registration Failed");
        
        //modal
        setRegistrationErrorMessage(response.data);
        //setIsSimpleModalOpen(true);
        
        //alert('Registration failed:' + response.data);
      }
    } catch (error) {
      setIsNetworkLoading(false);

      //alert("not ok 2");

      setRegistrationStatus("Registration Failed");
     // alert('Registration Failed:'+ error.response.data.message);

      // if (error.response && error.response.data && error.response.data.message) {
      //   // Print the specific error message received from the server
      //   //console.error('Registration Failed:'+ error.response.data.message);
    
      //   // You can also set the error message to a state variable if needed
      //   setRegistrationErrorMessage(error.response.data.data);
      // } else {
      //   // Handle other error cases if needed
      //   //console.error('An error occurred during registration.');

      //   setRegistrationErrorMessage('An error occurred during registration.');
      // }


      if (error.response && error.response.data && error.response.data.data) {
        const dataKeys = Object.keys(error.response.data.data);
    
        if (dataKeys.length > 0) {
          // Construct an error message from the data keys
          const errorMessage = dataKeys
            .map(key => {
              const messages = error.response.data.data[key];
              //return `${key}: ${messages.join(', ')}`;
              return `${messages.join(', ')}`;
            })
            .join('\n');
    
          setRegistrationErrorMessage(errorMessage);
        } else {
          // If there are no data keys, set a generic error message
          setRegistrationErrorMessage('An error occurred during registration.');
        }
      } else {
        // Handle other error cases if needed
        setRegistrationErrorMessage('An error occurred during registration.');
      }


      //modal
      //setIsSimpleModalOpen(true);

    }

  };



  // Function to handle input change and restrict to numbers only
  const handleAmountChange = (e) => {
    const input = e.target.value;
    const regex = /^\d*\.?\d*$/; // Regular expression to allow numbers and decimal point
  

    // Check if the input matches the regex pattern or is empty
    if (input.length <= 9) {
      if (input === '' || regex.test(input)) {
        // setAmountPaid(input); // Update the state with the new value
        setAmountPaid(parseFloat(input));
    }
    }else {
      alert("Length of amount must be less than 10 characters");
    }
    
};


// Function to handle input change and restrict to alphanumeric characters and spaces
const handleTellerNumberChange = (e) => {
  const input = e.target.value;
  // const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow alphanumeric characters and spaces
  const regex = /^[0-9\s]*$/; // Regular expression to allow alphanumeric characters and spaces

  // Check if the input matches the regex pattern or is empty
  if (input.length <= 30) {
    if (input === '' || regex.test(input)) {
      setTellerNumber(input); // Update the state with the new value
  }
  } else {
    alert("Length must be less than 30 characters");
  }
};



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Institution Modal"
      style={customModalStyles}
    >




<div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>Add Payment To {institution.name} Institution</h2>
  </div>
  <div>
    <button
      //onClick={onRequestClose}
      onClick={() => {
        onRequestClose();
        clearFields();
        setRegistrationErrorMessage('');
        setIsSimpleModalOpen(false);
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>
</div>

<div className="w-full px-8 pt-4">

      
{/* <div className="flex flex-col py-2">
  <label>Institution Id: <strong>{institution.id}</strong></label>
</div>

<div className="flex flex-col py-2">
    <label>Institution Name: <strong>{institution.name}</strong></label>
  </div>
  <br /> */}



  <div className="flex flex-col py-2">
          <label>Amount Paid</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Please enter amount"
            value={amountPaid}
            maxLength={9}
            // onChange={(e) => setAmountPaid(e.target.value)}
            onChange={handleAmountChange}
          />
        </div>

        <div className="flex flex-col py-2">
          <label>Year</label>
          {/* <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          /> */}
          <select
                style={{ backgroundColor: '#EDF2F6', width: '100%' }}
                className="rounded-lg mt-2 p-2 focus"
                value={year}
                //readOnly={additionalVariable === 'noEdit'}
                onChange={(e) => setYear(e.target.value)}>
                  <option value="">Select Year</option>
                {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
        </div>

        <div className="flex flex-col py-2">
          <label>Payment Date</label>
          {/* <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Set Payment Date: dd-MM-yyyy 01"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          /> */}
          <DatePicker 
                style={{ backgroundColor: '#EDF2F6', width: '100%' }}
                className="rounded-lg mt-2 p-2 focus bg-nbteDate"
                selected={paymentDate}
                onChange={date => setPaymentDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="Set Payment Date"
            />
          
        </div>

        <div className="flex flex-col py-2">
          <label>Teller Number</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Enter teller number"
            value={tellerNumber}
            maxLength={30}
            onChange={handleTellerNumberChange}
          />
        </div>

        <div className="flex flex-col py-2">
          <label>Comment</label>
          <textarea
            style={{ backgroundColor: '#EDF2F6', width: '100%', height: '200px' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

  

{/* 
  <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
  <p className='text-center mb-4 text-red-500'
  style={{ 
    //color: 'red',//'#990000', 
    fontSize: '12px' }}>{registrationError}</p>
</div> */}
<div className='text flex flex-col justify-center' style={{  }}>
                      {registrationError.split('\n').map((line, index) => (
                        <p key={index} className='text-center mb-' style={{ color: '#990000', fontSize: '12px' }}>
                          {line}
                        </p>
                      ))}
                    </div>





  <div className="text-center pt-2">
      <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
      onClick={hitAddPaymentToInsitutionEndpoint}
      >
        {isNetworkLoading ? (
                <LoadingScreen />
              ) : (
                'Add'
              )}
        </button>
      </div>


</div>

<SimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
              registrationError={registrationError}
              message='Payment'
/>

    </Modal>
  );
};

export default AddPaymentToInstitutionModal;
