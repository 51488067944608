import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useState, useEffect } from 'react';

import LoginPage from "./components/pages/landing/LoginPage";
import AdminLoginPage from "./components/pages/landing/AdminLoginPage";
import ContactAdmin from "./components/pages/landing/ContactAdmin";

import ZaptranceDashboard from "./components/pages/zaptrance/ZaptranceDashboard";
import ZaptranceInstitutions from "./components/pages/zaptrance/ZaptranceInstitutions";
import ZaptranceInstitutionDetail from "./components/pages/zaptrance/ZaptranceInstitutionDetail";
import ZaptrancePrograms from "./components/pages/zaptrance/ZaptrancePrograms";
import ZaptranceUser from "./components/pages/zaptrance/ZaptranceUser";
import ZaptranceUserDetail from "./components/pages/zaptrance/ZaptranceUserDetail";
import ZaptranceProfile from "./components/pages/zaptrance/ZaptranceProfile";
import ZaptrancePayment from "./components/pages/zaptrance/ZaptrancePayment";
import ZaptrancePaymentDetail from "./components/pages/zaptrance/ZaptrancePaymentDetail";
import ZaptranceSettingsPassword from "./components/pages/zaptrance/ZaptranceSettingsPassword";
import ZaptranceSettingsContact from "./components/pages/zaptrance/ZaptranceSettingsContact";
import ZaptranceVerificationRequest from "./components/pages/zaptrance/ZaptranceVerificationRequest";
import ZaptranceVerificationRequestDetail from "./components/pages/zaptrance/ZaptranceVerificationRequestDetail";
import ZaptranceLogout from "./components/pages/zaptrance/ZaptranceLogout";



import GuestLandingPage from "./components/pages/guest/GuestLandingPage";
import GuestRegistration from "./components/pages/guest/GuestRegistration";
import GuestDashboard from "./components/pages/guest/GuestDashboard";
import GuestRequestLists from "./components/pages/guest/GuestRequestLists";
import GuestRequestListsDetail from "./components/pages/guest/GuestRequestListsDetail";



import InstitutionsDashboard from "./components/pages/institutions/InstitutionsDashboard";
import InstitutionsCertificate from "./components/pages/institutions/InstitutionsCertificate";
import InstitutionsCertificateBatch from "./components/pages/institutions/InstitutionsCertificateBatch";
import InstitutionsAccreditedPrograms from "./components/pages/institutions/InstitutionsAccreditedPrograms";
import InstitutionsReports from "./components/pages/institutions/InstitutionsReports";
import InstitutionsProfile from "./components/pages/institutions/InstitutionsProfile";
import InstitutionsSettings from "./components/pages/institutions/InstitutionsSettings";


import RectorDashboard from "./components/pages/rector/RectorDashboard";
import RectorPayments from "./components/pages/rector/RectorPayments";
import RectorPrograms from "./components/pages/rector/RectorPrograms";
import RectorProfile from "./components/pages/rector/RectorProfile";
import RectorReport from "./components/pages/rector/RectorReport";
import RectorUsers from "./components/pages/rector/RectorUsers";
import RectorSettingsPassword from "./components/pages/rector/RectorSettingsPassword";

import RegistrarDashboard from "./components/pages/registrar/RegistrarDashboard";
import RegistrarPayments from "./components/pages/registrar/RegistrarPayments";
import RegistrarPrograms from "./components/pages/registrar/RegistrarPrograms";
import RegistrarProfile from "./components/pages/registrar/RegistrarProfile";
import RegistrarReport from "./components/pages/registrar/RegistrarReport";
import RegistrarUsers from "./components/pages/registrar/RegistrarUsers";
import RegistrarSettingsPassword from "./components/pages/registrar/RegistrarSettingsPassword";


import InstitutionsSideNav from './components/pages/institutions/InstitutionsSideNav';
import ZaptranceSideNav from './components/pages/zaptrance/ZaptranceSideNav';
import RectorSideNav from './components/pages/rector/RectorSideNav';
import RegistrarSideNav from './components/pages/registrar/RegistrarSideNav';
import GuestSideNav from './components/pages/guest/GuestSideNav';




function App() {
  const [userType, setUserType] = useState('');

  const handleLogout = () => {
    //localStorage.removeItem('user_type');
    //setUserType('');
    //localStorage.clear();
    setUserType('');
  };


  useEffect(() => {
    //localStorage.clear();
    
    const storedUserType = localStorage.getItem('user_type');
    setUserType(storedUserType || '');


    const handleBackButton = () => {
      //alert('Back button clicked!');

      if (window.location.pathname.includes("secure-nbte-login") ||
      window.location.pathname.includes("login")) {
        handleLogout();
      }
      // 
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  

  const handleLogin = () => {
    const storedUserType = localStorage.getItem('user_type');
    setUserType(storedUserType || '');

    //alert("# --> " + storedUserType);
  };


  return (
    <Router>

      <div className="flex">
        {
          userType === 'admin' ? 
          <InstitutionsSideNav /> :
          userType ===  'zaptrance' ? 
          <ZaptranceSideNav /> :
          userType ===  'rector' ? 
          <RectorSideNav /> :
          userType ===  'registrar' ? 
          <RegistrarSideNav /> :
          userType ===  'individual' ? 
          <GuestSideNav /> : 
          userType ===  'nbte' ? 
          <ZaptranceSideNav /> : 
          <div></div>
        }
        
        <div className="content flex-grow">
      
        <Routes>
        <Route path="/*" element={<div>NOT FOUND</div>} />

        <Route path='/' element={<GuestLandingPage />}/>
              
        <Route path='/secure-nbte-login' element={<AdminLoginPage onLogin={handleLogin} />} />        
        <Route path='/login' element={<LoginPage onLogin={handleLogin} />} />

        <Route path='/contact' element={<ContactAdmin />} />   


        <Route path='/zaptrance-dashboard' element={<ZaptranceDashboard />} />  
        <Route path='/zaptrance-institutions' element={<ZaptranceInstitutions />} /> 
        <Route path='/zaptrance-institution-detail/:institution/:additionalVariable' element={<ZaptranceInstitutionDetail />} />
        <Route path='/zaptrance-programs' element={<ZaptrancePrograms />} />  
        <Route path='/zaptrance-users' element={<ZaptranceUser />} />  
        <Route path='/zaptrance-users/:user/:additionalVariable' element={<ZaptranceUserDetail />} />  
        <Route path='/zaptrance-profile' element={<ZaptranceProfile />} />  
        <Route path='/zaptrance-payment' element={<ZaptrancePayment />} />  
        <Route path='/zaptrance-payment-detail/:payment' element={<ZaptrancePaymentDetail />} />  
        <Route path='/zaptrance-settings-password' element={<ZaptranceSettingsPassword />} />  
        <Route path='/zaptrance-settings-contact' element={<ZaptranceSettingsContact />} />  
        <Route path='/zaptrance-verification-request' element={<ZaptranceVerificationRequest />} />  
        <Route path='/zaptrance-verification-request/:guest/:additionalVariable' element={<ZaptranceVerificationRequestDetail />} />  


        <Route path='/guest-dashboard' element={<GuestDashboard />} />  
        <Route path='/guest-landing-page' element={<GuestLandingPage />} />  
        <Route path='/guest-registration' element={<GuestRegistration />} />  
        <Route path='/guest-request-lists' element={<GuestRequestLists />} />  
        <Route path='/guest-request-lists/:request/:additionalVariable' element={<GuestRequestListsDetail />} />  
        

        <Route path='/institutions-dashboard' element={<InstitutionsDashboard />} />  
        <Route path='/institutions-certificate' element={<InstitutionsCertificate />} />  
        <Route path='/institutions-accredited-programs' element={<InstitutionsAccreditedPrograms />} />  
        <Route path='/institutions-reports' element={<InstitutionsReports />} />  
        <Route path='/institutions-profile' element={<InstitutionsProfile />} />  
        <Route path='/institutions-settings' element={<InstitutionsSettings />} />  
        <Route path='/institutions-certificate-batch/:batch' element={<InstitutionsCertificateBatch />} />


        <Route path='/rector-dashboard' element={<RectorDashboard />} />  
        <Route path='/rector-payments' element={<RectorPayments />} />  
        <Route path='/rector-programs' element={<RectorPrograms />} />  
        <Route path='/rector-profile' element={<RectorProfile />} />  
        <Route path='/rector-report' element={<RectorReport />} />  
        <Route path='/rector-users' element={<RectorUsers />} /> 
        <Route path='/rector-settings-password' element={<RectorSettingsPassword />} />  
         

        <Route path='/registrar-dashboard' element={<RegistrarDashboard />} />  
        <Route path='/registrar-payments' element={<RegistrarPayments />} />  
        <Route path='/registrar-programs' element={<RegistrarPrograms />} />  
        <Route path='/registrar-profile' element={<RegistrarProfile />} />  
        <Route path='/registrar-report' element={<RegistrarReport />} />  
        <Route path='/registrar-users' element={<RegistrarUsers />} />  
        <Route path='/registrar-settings-password' element={<RegistrarSettingsPassword />} /> 







        <Route path='/logout' element={<ZaptranceLogout onLogout={handleLogout} />} />
        </Routes>

        </div>
      </div>

    </Router>
  );
}

export default App;
