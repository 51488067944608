import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';


export default function ComponentUsers({ currentInstitution}) {
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(currentInstitution.users ? currentInstitution.users.length : 0);



  useEffect(() => {
  }, [currentPage]);

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = currentInstitution.users ? currentInstitution.users.slice(indexOfFirstItem, indexOfLastItem) : [];

  let countUsers = indexOfFirstItem + 1;
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 pb-2">
        <div className="flex flex-col py-2 text-sm">
          <label>Users</label>
        </div>
      </div>
 

{
loading ? (
              <div className='p-4'>Loading...</div>
            ) : 
            (
              <div className='overflow-x-auto'>
                <table className='min-w-full bg-white shadow-md rounded-lg overflow-hidden'>
                  {/* Table Header */}
                  <thead>
                    <tr>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        S/N
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Name
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Type
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Status
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Email
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Email Verified
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Last Seen
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Created At
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Updated At
                      </th>
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    {currentUsers.map((user) => (
                      <tr key={user.id} >
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                        {countUsers++} {/* {user.id} */}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.name}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.type}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.status}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.email}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.email_verified}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {user.last_seen}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {/* {user.created_at} */}
                          {format(parseISO(user.created_at), "yyyy-MM-dd HH:mm")}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {/* {user.updated_at} */}
                          {format(parseISO(user.updated_at), "yyyy-MM-dd HH:mm")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
            }

            {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
          style={{width: '100px', borderRadius: '30px',}}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
          className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
          style={{width: '100px', borderRadius: '30px',}}
        >
          Next
        </button>
      </div>

    </div>
  );
}


