import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import RegistrarSideNav from './RegistrarSideNav';
import HeaderRegistrar from './HeaderRegistrar';



import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings';

import PasswordSimpleModal from './modals/PasswordSimpleModal';

const RegistrarSettingsPassword = () => {
    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
  
    const [isNetworkLoading, setIsNetworkLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [registrationError, setRegistrationErrorMessage] = useState('');


  
const closeSimpleModal = () => {
  
  if (registrationStatus == "Password Changed Successful") {
    //navigate("/");
    clearFields();
    setIsSimpleModalOpen(false);
    //onRequestClose();
    
  } else {
    setIsSimpleModalOpen(false);
  }
};


const clearFields = () => {
    

  setOldPassword('');
  setNewPassword('');
  setConfirmPassword('');

  setIsNetworkLoading(false);
setRegistrationStatus(null);
// const [isModalOpen, setIsModalOpen] = useState(false);
setIsSimpleModalOpen(false);
setRegistrationErrorMessage('');



}


    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        marginTop: '20px', // Add margin for spacing
    };

    const buttonStyle = {
        // Add your button styles here
        //height: 60,
    };


    let token = localStorage.getItem('token');
    useEffect(() => {
      if (!token) {
        // Redirect to the home page if the token is null
        navigate('/');
      } else {
        // If the user is authenticated, call the handleData function
        //handleData();
      }
    }, [navigate]);

    
    
  const hitChangePassword = async () => {
    
    //onAddInstitution(institutionName);
    //setInstitutionName('');

    setRegistrationErrorMessage('');
    setIsNetworkLoading(true);
    

    // alert(
    //     "oldPassword: " + oldPassword + "\n" + 
    //     "newPassword: " + newPassword + "\n" + 
    //     "confirmPassword: " + confirmPassword
    // );


    if (
      oldPassword  == ''  ||
      newPassword == '' ||
      confirmPassword == ''
      ) {

        const faultyFields = [];
        if (oldPassword == '') faultyFields.push("Old Password");
        if (newPassword == '') faultyFields.push("New Password");
        if (confirmPassword == '') faultyFields.push("Confirm Password");
        const errorMessage = `Please fill out all required fields: ${faultyFields.join(', ')}`;
        setRegistrationErrorMessage(errorMessage);

      //alert("Please select a type.");
      setIsNetworkLoading(false);
      return;
    }


    try {
      const token = localStorage.getItem('token')
      //alert(token);

      const formData = new FormData();
      formData.append('oldPassword', oldPassword);
      formData.append('password', newPassword);
      formData.append('password_confirmation', confirmPassword);
      

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/institution/change-password", formData, {
        headers: {
          //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        //alert("ok " + response.data.message);
        setRegistrationStatus("Password Change Successful");
        setIsSimpleModalOpen(true);
        //console.log('Registration successful:' + response.data.message);


        // clearFields();

        // // Close the modala
        // onAddUser();

        // You can also redirect the user to a confirmation page or do any other actions.
      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Password Change Failed");
        setIsSimpleModalOpen(true);
        //console.error('Registration failed:' + response.data);
        setRegistrationErrorMessage(response.data);
      }
    } catch (error) {
      setIsNetworkLoading(false);

      setRegistrationStatus("Password Change Failed");
      //console.error('Registration Failed:'+ error.message);

  if (error.response && error.response.data && error.response.data.data) {
    const dataKeys = Object.keys(error.response.data.data);

    try {
      if (dataKeys.length > 0) {
        // Construct an error message from the data keys
        const errorMessage = dataKeys
          .map(key => {
            const messages = error.response.data.data[key];
            //return `${key}: ${messages.join(', ')}`;
            return `${messages.join(', ')}`;
          })
          .join('\n');
  
        setRegistrationErrorMessage(errorMessage);
      } else {
        // If there are no data keys, set a generic error message
        setRegistrationErrorMessage('An error occurred during password change.');
      }
      setIsSimpleModalOpen(true);
    } catch {
      //alert(error.response.data.data);
      setRegistrationStatus("Password Change Failed");
        
      setRegistrationErrorMessage(error.response.data.data);
      setIsSimpleModalOpen(true);
    }

  } else {
    // Handle other error cases if needed
    setRegistrationErrorMessage('An error occurred during password change.');
    setIsSimpleModalOpen(true);
  }

    }
  };


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRegistrar />
          <div className=' p-4' style={{backgroundColor: '#F6F7FB'}}>
          <div className='text-black p-4 flex text-xl'>
                  <div><SettingsIcon className='mr-4'/></div>Settings
              </div>
              
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{backgroundColor: '#ffffff'}}> 

                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" > 
                          <div className='flex flex-col py-2'>
                              <label>Old Password</label>
                              <input style={{backgroundColor: '#EDF2F6'}}
                              className='rounded-l mt-2 p-2 focus' type='password'
                              value={oldPassword} 
                              placeholder='Please enter your old password'
                              onChange={(e) => setOldPassword(e.target.value)} />
                          </div>
                          <div className='flex flex-col py-2'>
                              <label>New Password</label>
                              <input style={{backgroundColor: '#EDF2F6'}}
                              className='rounded-l mt-2 p-2 focus' type='password'
                              value={newPassword} 
                              placeholder='Please enter your new password'
                              onChange={(e) => setNewPassword(e.target.value)} />
                          </div>
                          <div className='flex flex-col py-2'>
                              <label>Confirm Password</label>
                              <input style={{backgroundColor: '#EDF2F6'}}
                              className='rounded-l mt-2 p-2 focus' type='password' 
                              value={confirmPassword} 
                              placeholder='Please re-enter your new password'
                              onChange={(e) => setConfirmPassword(e.target.value)} />
                          </div>
                          
                          
                      </div>

                      <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
                          <p className='text-center mb-4 text-red-500'
                          style={{ 
                              //color: 'red',//'#990000', 
                              fontSize: '12px' }}>{registrationError}</p>
                      </div>


                  <div style={buttonContainerStyle} className='pt-8'>
                      <button className="rounded-button" style={buttonStyle} onClick={() => { hitChangePassword(); }}>Change Password</button>
                  </div>
                  </div>
              </div>

              <PasswordSimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
    registrationError={registrationError}
    message='Password'
/>

          </div>
      </div>
  </div>
    );
}

export default RegistrarSettingsPassword;
