import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import LoadingScreen from '../../../LoadingScreen';

import DatePicker from 'react-datepicker';
import '../../../Datepicker.css';
import { format } from 'date-fns';


const customModalStyles = {
  content: {
    width: '80%', 
    height: '80%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '1%',
  },
};

const AddPaymentModal = ({ isOpen, onRequestClose, onAddPayment, institutions }) => {
  

  //const [selectedFile, setSelectedFile] = useState({});


  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registrationError, setRegistrationErrorMessage] = useState('');
  const [isNetworkLoading, setIsNetworkLoading] = useState(false);


  const [institution, setInstitution] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [year, setYear] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [tellerNumber, setTellerNumber] = useState('');

  
  //
  const currentYear = new Date().getFullYear();
  const pastYears = Array.from({ length: 7 }, (_, i) => currentYear - i);
  //const pastYears = Array.from({ length: 5 }, (_, i) => currentYear - i).filter(year => year !== currentYear); // Exclude current year
  
  //const futureYears = Array.from({ length: 5 }, (_, i) => currentYear + i);
  // const years = [...pastYears, ...futureYears];
  const years = [...pastYears];
  //

  useEffect(() => {
    setInstitution('');
    setAmountPaid('');
    setYear('');
    setPaymentDate('');
    setTellerNumber('');
    
  }, []);


  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setInstitution(selectedValue);
  };


  const hitAddPaymentEndpoint = async () => {
    const token = localStorage.getItem('token')
    //onAddInstitution(institutionName);
    //setInstitutionName('');

    setRegistrationErrorMessage('');
    setIsNetworkLoading(true);

    // alert(
    //   'institution: ' + institution +
    //     '\namountPaid: ' + amountPaid +
    //     '\nyear: ' + year +
    //     '\npaymentDate: ' + paymentDate +
    //     '\ntellerNumber: ' + tellerNumber 
    // );


    // if (
    //   //institutionName  == ''  ||
    //   //onboardingYear == '' ||
    //   //email == '' ||
    //   //genType == ''
    //   ) {
    //   alert("Please select a type.");
    //   setIsNetworkLoading(false);
    //   return;
    // }

    //alert("!");
    let formattedDate = paymentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replace(/\//g, '-');    
    let pd = formattedDate;


    try {
      const formData = new FormData();
      //formData.append('name', "institutionName");
      formData.append('institution', institution);
      formData.append('amountPaid', amountPaid);
      formData.append('year', year);
      formData.append('paymentDate', pd);//paymentDate);
      formData.append('tellerNumber', tellerNumber);

      // Append the actual file to the formData
      //formData.append('paymentEvidence', selectedFile);

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/admin/payments", formData, {
        headers: {
          //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        alert("ok " + response.data.message);
        
        setRegistrationStatus("Registration Successful");
        setIsModalOpen(true);
        alert('Registration successful:' + response.data.message);
        // You can also redirect the user to a confirmation page or do any other actions.

        clearFields();


        // Close the modal
        onAddPayment();

      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Registration Failed");
        setRegistrationErrorMessage('Registration failed:' + response.data);
        //setIsModalOpen(true);
        // alert('Registration failed:' + response.data);
      }
    } catch (error) {
      setIsNetworkLoading(false);
      setRegistrationStatus("Registration Failed");
      setRegistrationErrorMessage(error.message);

      //alert('Registration Failed:'+ error);
      // Handle request error, e.g., display an error message to the user


  if (error.response && error.response.data && error.response.data.data) {
    const dataKeys = Object.keys(error.response.data.data);

    if (dataKeys.length > 0) {
      // Construct an error message from the data keys
      const errorMessage = "Please fill out all required fields:\n" + dataKeys
        .map(key => {
          const messages = error.response.data.data[key];
          //return `${key}: ${messages.join(', ')}`;
          return `${messages.join(', ')}`;
        })
        .join('\n');

      setRegistrationErrorMessage(errorMessage);
    } else {
      // If there are no data keys, set a generic error message
      setRegistrationErrorMessage('An error occurred during registration.');
    }
  } else {
    // Handle other error cases if needed
    setRegistrationErrorMessage('An error occurred during registration.');
  }
    }
  };

  const clearFields = () => {
    //
    setInstitution('');
    setAmountPaid('');
    setYear('');
    setPaymentDate('');
    setTellerNumber('');

    setRegistrationStatus(null);
    setIsModalOpen(false);
    setRegistrationErrorMessage('');
  
          //
  }


  // Function to handle input change and restrict to numbers only
  const handleAmountChange = (e) => {
    const input = e.target.value;
    const regex = /^\d*\.?\d*$/; // Regular expression to allow numbers and decimal point
  

    // Check if the input matches the regex pattern or is empty
    if (input.length <= 9) {
      if (input === '' || regex.test(input)) {
        // setAmountPaid(input); // Update the state with the new value
        setAmountPaid(parseFloat(input));
    }
    }else {
      alert("Length of amount must be less than 10 characters");
    }
    
};


// Function to handle input change and restrict to alphanumeric characters and spaces
const handleTellerNumberChange = (e) => {
  const input = e.target.value;
  // const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow alphanumeric characters and spaces
  const regex = /^[0-9\s]*$/; // Regular expression to allow alphanumeric characters and spaces

  // Check if the input matches the regex pattern or is empty
  if (input.length <= 30) {
    if (input === '' || regex.test(input)) {
      setTellerNumber(input); // Update the state with the new value
  }
  } else {
    alert("Length must be less than 30 characters");
  }
};


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Payment Modal"
      style={customModalStyles}
    >
      <div className="flex justify-between items-center w-full px-2 pt-42">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>New Payment</h2>
  </div>
  <div>
    <button
      onClick={onRequestClose}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>
</div>


      <div className="w-full px-2 pt-4">
        <div className="flex flex-col py-2">
          <label>Institution</label>
          {/* <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Please enter your institution"
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
          /> */}
          <select
          className="mt-2 p-2 focus rounded-lg"
          onChange={(e) => handleDropdownChange(e)}
        >
          <option value="Select">Select</option>
          {institutions.map((institution, index) => (
  <option key={index} value={institution.id}>
    {institution.name}
  </option>
))}
        </select>
        </div>

        <div className="flex flex-col py-2">
          <label>Amount Paid</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Please enter amount"
            value={amountPaid}
            maxLength={9}
            onChange={handleAmountChange}
          />
        </div>

        <div className="flex flex-col py-2">
          <label>Year</label>
          {/* <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          /> */}
          <select
                style={{ backgroundColor: '#EDF2F6', width: '100%' }}
                className="rounded-lg mt-2 p-2 focus"
                value={year}
                //readOnly={additionalVariable === 'noEdit'}
                onChange={(e) => setYear(e.target.value)}>
                  <option value="">Select Year</option>
                {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
        </div>

        <div className="flex flex-col py-2">
          <label>Payment Date</label>
          {/* <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Set Payment Date: dd-MM-yyyy 01-01-2001"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          /> */}
          <DatePicker 
                style={{ backgroundColor: '#EDF2F6', width: '100%' }}
                className="rounded-lg mt-2 p-2 focus bg-nbteDate"
                selected={paymentDate}
                onChange={date => setPaymentDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="Set Payment Date"
            />
        </div>

        <div className="flex flex-col py-2">
          <label>Teller Number</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Enter teller number"
            value={tellerNumber}
            maxLength={30}
            onChange={handleTellerNumberChange}
          />
        </div>


{/* <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
        <p className='text-center mb-4 text-red-500'
        style={{ 
          //color: 'red',//'#990000', 
          fontSize: '12px' }}>{registrationError}</p>
      </div> */}
      <div className='text flex flex-col justify-center' style={{  }}>
                      {registrationError.split('\n').map((line, index) => (
                        <p key={index} className='text-center mb-' style={{ color: '#990000', fontSize: '12px' }}>
                          {line}
                        </p>
                      ))}
                    </div>

        

        <div className="text-right">
            <button className="px-6 py-3 m-4" 
            style={{
            width: '200px',
            backgroundColor: '#095918',
            borderRadius: '8px',
            padding: '10px 20px',
            color: 'white',
            border: 'none',
          }}
            onClick={hitAddPaymentEndpoint}
            >
              {isNetworkLoading ? (
                      <LoadingScreen />
                    ) : (
                      'Save'
                    )}
              </button>
            </div>


      </div>
    </Modal>
  );
};

export default AddPaymentModal;
