import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import ZaptranceSideNav from './RectorSideNav';
import HeaderRectorAndRegistrar from './HeaderRector';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

const RectorAndRegistrarUsers = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [users, setNoOfUsers] = useState([]);

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/users', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setNoOfUsers(response.data.data);
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  let count = 1;

    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRectorAndRegistrar />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Users
              </div>
              {/* <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#FFE5D3' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfCertificates}
                      </h1>
                      <div>Certificates generated</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#E2FBD7' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfPrograms}
                      </h1>
                      <div>Accredited Programs</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#DAD7FE' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfInstitutions}
                      </h1>
                      <div>Institutions</div>
                  </div>
              </div> */}
              {/* <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Recent Users</div> */}
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      {/* <div className='text-xl pb-2'>Activities</div> */}
                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              SN
            </th>
            
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Fullname
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Institution
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Last Seen
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Email
            </th>
            
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Type
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Status
            </th>
            
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {count++} {/*{payment.id} */}
              </td>
              
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {user.fullName}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {user.institution}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {user.last_seen}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {user.email}
              </td>
              
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {user.type}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 " >
                <div className="text-center px-2 py-1" style={{ 
                  borderRadius: '10px', 
                  color: user.status === 'active' ? 'green' : 'red',
                  background: '#eeeeee' }}>{user.status}
                  </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default RectorAndRegistrarUsers;
