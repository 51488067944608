import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';

import LogoutIcon from '@mui/icons-material/Logout';

import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';



export const navData = [
    // {
    //     id: 0,
    //     icon: <HomeIcon/>,
    //     text: "Home",
    //     link: "home"
    // },
    {
        id: 1,
        icon: <AppsIcon/>,
        text: "Dashboard",
        link: "registrar-dashboard"
    },
    {
        id: 2,
        icon: <PaymentIcon/>,
        text: "Payment",
        link: "registrar-payments"
    },
    {
        id: 3,
        icon: <LayersIcon/>,
        text: "Programs",
        link: "registrar-programs"
    },
    {
        id: 4,
        icon: <PersonIcon/>,
        text: "Profile",
        link: "registrar-profile"
    },
    {
        id: 5,
        icon: <AssessmentIcon/>,
        text: "Report",
        link: "registrar-report"
    },    
    {
        id: 6,
        icon: <PeopleIcon/>,
        text: "Users",
        link: "registrar-users"
    },
    {
        id: 7,
        icon: <SettingsIcon/>,
        text: "Settings",
        link: "registrar-settings-password"
    },
    {
        id: 8,
        icon: <LogoutIcon/>,
        text: "Logout",
        link: "logout"
    },
]