import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import HeaderInstitutions from './HeaderInstitutions';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import logginImgTwo from '../../../assets/logo.png';

const InstitutionsProfile = () => {
  
  const navigate = useNavigate();

    const [institutionName, setInstitutionName] = useState('Please enter your full name');
    const [email, setEmail] = useState('Please enter your email');
    const [phoneNumber, setPhoneNumber] = useState('Please enter your phone number');
    const [institutionType, setInstitutionType] = useState('Polytechnic');

    const [address, setAddress] = useState('Institution Address');
    const [state, setState] = useState('Please enter your location');
    const [city, setCity] = useState('Please enter your location');
    const [postalCode, setPostalCode] = useState('33902');

  // Define state variables to store data
  const [noOfUsers, setNoOfUsers] = useState([]);
  const [noOfInstitutions, setNoOfInstitutions] = useState([]);
  const [noOfCertificates, setNoOfCertificates] = useState([]);
  const [noOfPrograms, setNoOfPrograms] = useState([]);

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'right', // Center horizontally
    marginTop: '20px', // Add margin for spacing
  };

  const buttonStyle = {
    // Add your button styles here
    //height: 60,
  };


  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setNoOfUsers(response.data.data.users);
        setNoOfInstitutions(response.data.data.institutions);
        setNoOfCertificates(response.data.data.certificates);
        setNoOfPrograms(response.data.data.programs);
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setPhoneNumber(value);
  };


    return (
      <div className="content flex-grow">
          <div className="inner-content">
              <HeaderInstitutions />
              <div className=' p-4' style={{backgroundColor: '#F6F7FB'}}>
              <div className='text-black p-4 flex text-xl'>
                      <div><LayersIcon className='mr-4'/></div>Profile
                  </div>
                  
                  <img className="w-112 h-40 " src={logginImgTwo} alt="" />


                  <div className="flex justify-between">
                      <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{backgroundColor: '#ffffff'}}> 

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                          <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" > 
                              <div className='flex flex-col py-2'>
                                  <label>Institution Name</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={institutionName}
                                  //value={institutionName} 
                                  onChange={(e) => setInstitutionName(e.target.value)} />
                              </div>
                              <div className='flex flex-col py-2'>
                                  <label>Email</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={email}
                                  //value={email} 
                                  onChange={(e) => setEmail(e.target.value)} />
                              </div>
                              <div className='flex flex-col py-2'>
                                  <label>Phone Number</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='number'
                                  pattern='[0-9]*'
                                  placeholder={phoneNumber}
                                  value={phoneNumber} 
                                  onChange={handlePhoneNumberChange} 
                                  />
                              </div>
                              <div className='flex flex-col py-2'>
                                  <label>Institution Type</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={institutionType}
                                  //value={institutionType} 
                                  onChange={(e) => setInstitutionType(e.target.value)} />
                              </div>
                          </div>
                          <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" > 
                          <div className='flex flex-col py-2'>
                                  <label>Address</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={address}
                                  //value={address} 
                                  onChange={(e) => setAddress(e.target.value)} />
                              </div>
                              <div className='flex justify-between'>
                              <div className='flex flex-col py-2'>
                                  <label>State</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={state}
                                  //value={state} 
                                  onChange={(e) => setState(e.target.value)} />
                              </div>
                              <div className='flex flex-col py-2'>
                                  <label>City</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={city}
                                  //value={state} 
                                  onChange={(e) => setCity(e.target.value)} />
                              </div>
                              </div>
                              <div className='flex flex-col py-2'>
                                  <label>Postal Code</label>
                                  <input style={{backgroundColor: '#EDF2F6'}}
                                  className='rounded-l mt-2 p-2 focus' 
                                  type='text' 
                                  placeholder={postalCode}
                                  //value={postalCode} 
                                  onChange={(e) => setPostalCode(e.target.value)} />
                              </div>
                          </div>
                      </div>
                      <div style={buttonContainerStyle} className='pt-8'>
                          <button className="rounded-button" style={buttonStyle}>Update Info</button>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
}

export default InstitutionsProfile;
