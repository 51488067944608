import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

export default function HeaderWithHome() {
  const buttonContainerStyle = {
    // Center horizontally on small screens and add padding
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem', // Adjust the padding as needed
  };

  const buttonStyle = {
    // Add your button styles here
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 items-center container mx-auto">
      <div className="flex items-center">
        <img src={logo} alt="Logo" />
      </div>

      <div>
        <div style={buttonContainerStyle}>
          <Link to="/">
            <button className="rounded-button" style={buttonStyle}>
              Back to Homepage
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
