import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderZaptrance from './HeaderZaptrance';
import ZaptranceSideNav from './ZaptranceSideNav';
import Modal from 'react-modal';

import { format, parseISO } from 'date-fns';
import axios from 'axios';

import AddInstitutionModal from './modals/AddInstitutionModal';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/ModeOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import FilterIcon from '@mui/icons-material/FilterListOutlined';


import AddProgramToInstitutionModal from './modals/AddProgramToInstitutionModal';

import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';



const ZaptranceInsitutions = () => {
  const navigate = useNavigate();
  
  // Define state variables to store data
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  
  //const [programTypes, setProgramTypes] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(institutions ? institutions.length : 0);



  //sorting
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [sortColumn, setSortColumn] = useState('name'); // Default column to sort
  //sorting



  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);
  const openAddProgramModal = () => {
    setIsAddProgramModalOpen(true);
  };

  // Function to close the modal
  const closeAddProgramModal = () => {
    setIsAddProgramModalOpen(false);
  };





  // Function to open the modal
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  // Function to close the modal
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleAddInstitution = (institutionName) => {
    // Close the modal when the operation is complete
    closeAddModal();

    //retrive
    handleData();
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredInstitutions = institutions.filter((institution) =>
  institution.name.toLowerCase().includes(searchQuery.toLowerCase())
  );



  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      handleData();
    }
  }, [currentPage, navigate]);



  const handleData = async () => { 
    setLoading(true);
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin/institutions', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      if (response.data.success) {
        //alert("institutions: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state
        setInstitutions(response.data.data);
        setTotalItems(response.data.data.length);
         // Set loading to false when data is received

        //await getProgramTypes();
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  const handleChangeStatus = async (institution, value) => { 

    //alert(institution.id + " status: " + value);
    //return;

    setLoading(true);
    const formData = new FormData();
    formData.append('status', value);

    try {
      const response = await axios.patch(`https://gacsstage.zaptrance.ng/api/admin/institutions/${institution.id}/status`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      if (response.data.success) {
        //alert("institution update status: " + JSON.stringify(response.data.data, null, 2));
        await handleData();

      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  

  const handleEditOnClick = (institution, e) => {
    e.stopPropagation();
    //alert(JSON.stringify(institution, null, 2));
    //alert(registration.id);

    setSelectedRow(institution);
    setSelectedInstitution(institution);
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    const institutionString = JSON.stringify(institution);
    // Navigate to the detail page with the serialized institution as a parameter
    const additionalVariable = 'edit';
    //navigate(`/zaptrance-institution-detail/${encodeURIComponent(institutionString)}`);
    navigate(`/zaptrance-institution-detail/${encodeURIComponent(institutionString)}/${additionalVariable}`);

  };

  const handleChangeDataClick = (institution, e) => {
    e.stopPropagation();

    //alert(JSON.stringify(institution, null, 2));

    let option = institution.status === 'active' ? 'inactive' : 'active' ;
    handleChangeStatus(institution, option);
  };



  
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  // const [showDialog, setShowDialog] = useState(false);


  const handleRowClick = (institution, e) => {
    
    //alert(JSON.stringify(institution, null, 2));
    //alert(registration.id);

    setSelectedRow(institution);
    setSelectedInstitution(institution);
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    const institutionString = JSON.stringify(institution);
    // Navigate to the detail page with the serialized institution as a parameter
    const additionalVariable = 'noEdit';
    //navigate(`/zaptrance-institution-detail/${encodeURIComponent(institutionString)}`);
    navigate(`/zaptrance-institution-detail/${encodeURIComponent(institutionString)}/${additionalVariable}`);

  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredInstitutions = filteredInstitutions ? filteredInstitutions.slice(indexOfFirstItem, indexOfLastItem) : [];
  let countInstitutions = indexOfFirstItem + 1;



  const handleSort = (column) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(order);
  
    // Create a copy of the institutions array and sort it
    if (column == "name") {
      const sortedInstitutions = [...institutions].sort((a, b) => {
        const valueA = a[column].toLowerCase();
        const valueB = b[column].toLowerCase();
    
        if (valueA < valueB) return order === 'asc' ? -1 : 1;
        if (valueA > valueB) return order === 'asc' ? 1 : -1;
        return 0;
      });
    
      setInstitutions(sortedInstitutions);
    } else if (column == "user" || column == "certificates") {
      const sortedInstitutions = [...institutions].sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];
    
        if (valueA < valueB) return order === 'asc' ? -1 : 1;
        if (valueA > valueB) return order === 'asc' ? 1 : -1;
        return 0;
      });
    
      setInstitutions(sortedInstitutions);
    } 
    
  };



  return (
    <div className="content flex-grow">
        <div className="inner-content">
          <HeaderZaptrance />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
          <div className='text-black p-4 flex text-xl'>
                            <div><BusinessIcon className='mr-4'/></div >Institutions
                        </div>

            <div className="text-right">
            <button className="px-6 py-3 m-4" 
            style={{ width: '200px', 
            backgroundColor: '#095918', borderRadius: '8px', 
            padding: '10px 20px', color: 'white', border: 'none', }}
            onClick={openAddModal}
            >
              Add Institution
              </button>
            </div>
            
            {loading ? (
              <div className="p-4">Loading...</div>
            ) : (
              <div>
                
                <div className="flex justify-between my-2 pb-2 ">
  <div className="flex items-center">
    <div className="text-xl">Institution List</div>
  </div>
  <div className="flex items-center">
    <input
      type="text"
      placeholder="Search"
      className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
      onChange={handleSearchChange}
    />
    <div className="flex items-center">
      <FilterIcon className="text-gray-500 cursor-pointer" />
      <span className="text-gray-500 ml-1">Filter Search</span>
    </div>
  </div>
</div>

              <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                  <thead>
                    
                    <tr>
                    <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        S/N
                      </th>
                      <th style={{backgroundColor: '#095918', cursor: "pointer"}} 
                      onClick={() => handleSort('name', )}
                      className="px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                        Institution
                        {sortColumn === 'name' && (
                          <span className='pl-2' style={{  }}>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                          )}
                      </th>
                      <th style={{backgroundColor: '#095918', cursor: "pointer"}} 
                      onClick={() => handleSort('user')}
                      className="px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                        Users
                        {sortColumn === 'user' && (
                          <span className='pl-2' style={{  }}>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                          )}
                      </th>
                      <th style={{backgroundColor: '#095918', cursor: "pointer"}} 
                      onClick={() => handleSort('certificates')}
                      className="px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                        Certificates Generated
                        {sortColumn === 'certificates' && (
                          <span className='pl-2' style={{  }}>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                          )}
                      </th>
                      <th style={{backgroundColor: '#095918'}} className="px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                        Action
                      </th>
                      <th style={{backgroundColor: '#095918'}} className="px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentFilteredInstitutions.map((institution) => (
                        <tr key={institution.id} onClick={(e) => handleRowClick(institution, e)} style={{ cursor: "pointer" }}>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                        {countInstitutions++} {/* {user.id} */}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {institution.name}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {institution.user}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {institution.certificates}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <span className="mr-4" style={{ color: 'green' }} onClick={(e) => handleEditOnClick(institution, e)} ><EditIcon /></span>
                          <span className="mr-4" 
                          style={{ color:  institution.status === 'active' ? 'green' : 'red' }} onClick={(e) => handleChangeDataClick(institution, e)} >{ institution.status === 'active' ? <ToggleOnIcon /> : <ToggleOffIcon />}</span>
                          <span className="" style={{ color: 'green' }}>More</span>
                          
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 " >
                          <div className="text-center px-2 py-1" style={{ 
                            borderRadius: '10px', 
                            color: institution.status === 'active' ? 'green' : 'red',
                            background: '#eeeeee' }}>{institution.status}
                            </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              </div>
            )}

            {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
          style={{width: '100px', borderRadius: '30px',}}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
          className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
          style={{width: '100px', borderRadius: '30px',}}
        >
          Next
        </button>
      </div>




<AddInstitutionModal
              isOpen={isAddModalOpen}
              onRequestClose={closeAddModal}
              onAddInstitution={handleAddInstitution}
            />
          {/* <AddProgramToInstitutionModal
              isOpen={isAddProgramModalOpen}
              onRequestClose={closeAddProgramModal}
              institution={selectedInstitution}
              programTypes={programTypes}
              
            /> */}

          </div>
        </div>
      </div>
  );
};

export default ZaptranceInsitutions;
