import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import HeaderInstitutions from './HeaderInstitutions';

import axios from 'axios';

import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import FilterIcon from '@mui/icons-material/FilterListOutlined';

import SimpleModal from './modals/SimpleModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

const InstitutionsCertificatebatch = () => {
  
  const navigate = useNavigate();

  const [selectedProgramTypeIndexId, setSelectedProgramTypeIndexId] = useState("");
  const [selectedProgramIndexId, setSelectedProgramIndexId] = useState("");


  const [batchCertificates, setBatchCertificates] = useState([]);


  const [loading, setLoading] = useState(true);


  const [certificateUploadErrorMessage, setCertificateUploadErrorMessage] = useState('');
  const [isNetworkLoading, setIsNetworkLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);  


  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [registrationError, setRegistrationErrorMessage] = useState('');
  

  const closeSimpleModal = () => {
    setIsSimpleModalOpen(false);
    //clearFields();
    if (registrationStatus == "Upload Certificate Successful") {
      //navigate("/");
      //clearFields();
      //onRequestClose();
      

    }
    
  };



  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleBatchData();

    }
  }, [navigate]);


  //let params = useParams();
  const { batch } = useParams();
  const parsedCertificateBatch = JSON.parse(decodeURIComponent(batch));
  //alert("parsedInstitution: " + parsedInstitution.id);
  
  const handleBatchData = async () => { 
    
    setLoading(true);
    try {
      const response = await axios.get("https://gacsstage.zaptrance.ng/api/institution-admin/certificates/batch/" + parsedCertificateBatch.batch, { //aftewrwards : {{url}}/institution/type/99c5937b-3ab0-4864-b47c-69220d0d782d/programs
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);

      if (response.data.success) {
        //alert("batchCertificates: " + JSON.stringify(response.data.data, null, 2));

        setBatchCertificates(response.data.data);
        
      } else {
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);

      alert('Request error:'+ error);  
    }
  };
  
  const handleRowClick = (batchCertificate, e) => {
    e.stopPropagation();
    // setSelectedRow(certificate);
    // setSelectedCertificate(certificate);

    // ------------------------------------
    //alert(JSON.stringify(batchCertificate, null, 2));
    //alert(registration.id);

    
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    //const certificateBatchString = JSON.stringify(certificate);    
    //const additionalVariable = 'noEdit';
    
    //navigate(`/zaptrance-institution-certificate-batch/${encodeURIComponent(institutionString)}/${additionalVariable}`);
    //navigate(`/institutions-certificate-batch/${encodeURIComponent(certificateBatchString)}`);
  };

  const downloadSingleQRCode = async (qrLink) => {
    //e.stopPropagation();
    //alert("DownloadSingleQRCode");
    alert("https://gacsstage.zaptrance.ng/" + qrLink);
    try {
      // Replace the file URL with the actual URL of your Excel file
      const qrFileUrl = "https://gacsstage.zaptrance.ng/" + qrLink;
  
      // Open the file URL in a new window or tab to initiate the download
      window.open(qrFileUrl, '_blank');
    } catch (error) {
      alert('Error downloading QR file:'+ error);
    }
    //batchCertificate.qrLink
  }

  
  const downloadSingleCertificates = async (certLink) => {
    //e.stopPropagation();
    //alert("DownloadSingleCertificates");
    alert("https://gacsstage.zaptrance.ng/" + certLink);
    try {
      // Replace the file URL with the actual URL of your Excel file
      const certFileUrl = "https://gacsstage.zaptrance.ng/" + certLink;
  
      // Open the file URL in a new window or tab to initiate the download
      window.open(certFileUrl, '_blank');
    } catch (error) {
      alert('Error downloading Cert file:'+ error);
    }
    //batchCertificate.certLink
  }


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(batchCertificates ? batchCertificates.length : 0);
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredBatchCertificates = batchCertificates.filter((batchCertificate) =>
  batchCertificate.certificate_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
  batchCertificate.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  batchCertificate.registration_number.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredBatchCertificates = filteredBatchCertificates ? filteredBatchCertificates.slice(indexOfFirstItem, indexOfLastItem) : [];
  let countBatchCertificates = indexOfFirstItem + 1;


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderInstitutions />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Certificate Batch
              </div>                        
            
              <div className="flex justify-between">
              <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
              <div className="flex justify-between items-center w-full px-2 pt-4">
              <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Batch History</div>


<div>
<button
onClick={()=> {
navigate("/institutions-certificate");
}}
className="border p-2"
style={{
background: 'none',
cursor: 'pointer',
borderRadius: '50%',
borderColor: 'black',
display: 'inline-block',
width: '30px',
height: '30px',
padding: '0',
}}
>
<span aria-hidden="true">
<FontAwesomeIcon icon={faTimes} color="black" />
</span>
</button>
</div>


</div>
                    
{loading ? (
    <div className='p-4'>Loading...</div>
  ) : (
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      
                      <div className="flex justify-between my-2 pb-2">
                      {/* <div className='text-xl pb-2'>Activities</div> */}
                     
                      <div className="flex items-center">
<input
type="text"
placeholder="Search"
className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
onChange={handleSearchChange}
/>
<div className="flex items-center">
<FilterIcon className="text-gray-500 cursor-pointer" />
<span className="text-gray-500 ml-1">Filter Search</span>
</div>
</div>
                      
                  </div>
                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        <thead>
          <tr>
          <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              S/N
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Certificate Number
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Name
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Registration Number
            </th>
            {/* <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              QRLink
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              CertLink
            </th> */}
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Year
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Grade
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program Type
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Qr
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Certificates
            </th>
            
          </tr>
        </thead>
        
        <tbody>
          {currentFilteredBatchCertificates.map((batchCertificate) => (
              <tr key={batchCertificate.id} onClick={(e) => handleRowClick(batchCertificate, e)} style={{ cursor: "pointer" }}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {countBatchCertificates++} 
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.certificate_number}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.name}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.registration_number}
              </td>
              {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.qrLink}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.certLink}
              </td> */}
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.program}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.year}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.grade}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {batchCertificate.program_type}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  }}  onClick={(e) => {
                    e.stopPropagation();
                    downloadSingleQRCode(batchCertificate.qrLink);
                  }}>QRCode</span>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  }}
                   onClick={(e) => {
                    e.stopPropagation();
                    downloadSingleCertificates(batchCertificate.certLink)
                   }}>Certificate</span>           
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
)}

{/* Pagination Controls */}
<div className="flex justify-center mt-4">
<button
onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
disabled={currentPage === 1}
className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Previous
</button>
<button
onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Next
</button>
</div>



                  </div>
              </div>

              
              <SimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
    registrationError={registrationError}
    message='Certificate'
/>

          </div>
      </div>
  </div>
    );
}

export default InstitutionsCertificatebatch;
