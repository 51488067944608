import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import landingPageImage from '../../../assets/landingPageImage.png';
import watermarkImage from '../../../assets/watermark.png';
import Header from "../../Header";
import Footer from "../../Footer";

export default function ContactAdmin() {
    const [email, setEmail] = useState('Please enter your email');
    const [phoneNumber, setPhoneNumber] = useState('Please enter your phone number');
    const [organization, setOrganization] = useState('Please enter your organization');
    const [message, setMessage] = useState('Please enter your message');


    const pageStyle = {
        height: '100vh', // 100% of the viewport height
        margin: '0',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    };

    const contentContainerStyle = {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        //backgroundColor: '#dddddd'
    };

    const leftContentStyle = {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //alignItems: 'center',
        padding: '20px', // Adjust padding as needed
    };

    const rightContentStyle = {
        flex: '1',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        marginTop: '20px', // Add margin for spacing
    };

    const buttonStyle = {
        // Add your button styles here
        //height: 60,
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setPhoneNumber(value);
      };

    return (
        <div>
            <Header />
            <div style={pageStyle}>
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                        <div className="flex flex-col justify-center p-6">
                            <h1 style={{ fontSize: '36px', fontWeight: 'bold' }} className='py-2'>
                            Contact Admin
                            </h1>
                            <p style={{ fontSize: '16px' }} className='py-2'>
                            Complete the following information to get<br />
                            response from admin.
                            </p>
                        </div>
                        <div className="justify-center p-6">
                            <div className='space-y-2'>
                            <div className='flex flex-col py-2'>
                            <label>Email</label>
                            <input style={{backgroundColor: '#EDF2F6'}}
                            className='rounded-l mt-2 p-2 focus' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='flex flex-col py-2'>
                            <label>Phone Number</label>
                            <input style={{backgroundColor: '#EDF2F6'}}
                            className='rounded-l mt-2 p-2 focus' type='text' value={phoneNumber} onChange={handlePhoneNumberChange} />
                        </div>
                        <div className='flex flex-col py-2'>
                            <label>Organization</label>
                            <input style={{backgroundColor: '#EDF2F6'}}
                            className='rounded-l mt-2 p-2 focus' type='text' value={organization} onChange={(e) => setOrganization(e.target.value)} />
                        </div>
                        <div className='flex flex-col py-2'>
                            <label>Message</label>
                            <input style={{backgroundColor: '#EDF2F6'}}
                            className='rounded-l mt-2 p-2 focus' type='text' value={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>
                            
                        <div style={buttonContainerStyle} className='pt-8'>
                            <button className="rounded-button" style={buttonStyle}>Send Message</button>
                        </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
}
