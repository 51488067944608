import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import landingPageImage from '../../../assets/landingPageImage.png';
import watermarkImage from '../../../assets/watermark.png';
import Header from '../../Header';
import Footer from '../../Footer';
import LoadingScreen from '../../LoadingScreen';

import RegistrationModal from '../landing/modals/RegistrationModal';


import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';


export default function GuestRegistration() {

  const navigate = useNavigate();

  let a1 = 'Please enter your fullname';
  let a2 = 'Please enter your email';
  let a3 = 'Please enter a password';
  let a3a = 'Please enter confirmpassword';
  let a4 = 'Specify identity type';
  let a5 = 'Specify type';
  let a6 = 'Please enter your phone number';
  let a7 = 'Select identity file';

  const [fullname, setFullname] = useState(a1);
  const [email, setEmail] = useState(a2);
  const [password, setPassword] = useState(a3);
  const [confirmPassword, setConfirmPassword] = useState(a3a);
  const [identity, setIdentityType] = useState(a4);
  const [type, setType] = useState(a5);
  const [phoneNumber, setPhoneNumber] = useState(a6);
  const [identityTypeFile, setIdentityTypeFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegistrationLoading, setIsRegistrationLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);

  const fileInputRef = useRef(null);
  

  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {

    localStorage.setItem('token', "");
    localStorage.setItem('user_type', null);
    localStorage.clear();
    // onLogout();

    // navigate('/');
  }, []);

  const pageStyle = {
    //height: '100vh', // 100% of the viewport height
    margin: '0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    //backgroundColor: '#095918',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    //marginTop: '20px', // Add margin for spacing

    flexDirection: 'column',
  alignItems: 'center', // Center horizontally
  };

  const buttonStyle = {
    // Add your button styles here
    width: 220,
  };

  // Add CSS styles for making the form scrollable on smaller screens
  const formContainerStyle = {
    overflowY: 'auto', // Enable vertical scrolling
    //maxHeight: 'calc(100vh - 150px)', // Adjust max height as needed
    // maxHeight: '700px', // Adjust max height as needed
  };

  const clearFields = () => {
    //
    setFullname('');
    setEmail('');
    setPassword('');
    setIdentityType('');
    setIdentityTypeFile({}); 
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setType([]); 
    setPhoneNumber('');
    setErrorMessage('');
          //
  }

  const openAddModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeAddModal = () => {
    setIsModalOpen(false);
    if (registrationStatus == "Registration Successful") {
      //navigate("/");
      //navigate("/guest-dashboard");
      //window.location.reload();
      navigate("/login");
    } else {
      navigate("/guest-registration");
    }
    
  };

  const handlePreviewChange = (e) => {
    const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (readerEvent) => {
      setPreview(readerEvent.target.result);
    };

    reader.readAsDataURL(file);
  }
  };

  // Function to validate email format
  const isValidEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleRegistration = async () => {

    // Validate email before proceeding
    if (!isValidEmail(email)) {
      alert('Invalid email address');
      //setRegistrationStatus("Invalid email address");
      //setIsModalOpen(true);
      return;
    }


      // Check if a file has been selected
      if (setIdentityTypeFile.name == null) {
        alert("Please select an Identity Type file.");
        setIsRegistrationLoading(false);
        return;
      }

    setErrorMessage('');
    setIsRegistrationLoading(true);

    //to="/login"

    //alert(selectedFile.name);
    //alert(selectedFile.name == null ? 'null' : 'notnull');
    //alert(JSON.stringify(selectedFile) === null ? 'null' : 'notnull');
    //alert(attendanceType);
    //alert(selectedValues);
    //return;
    
    

    // Check if other fields are valid
    if (
      fullname === a1 ||
      email === a2 ||
      password === a3 ||
      identity === a4 ||
      type === a5 ||
      phoneNumber === a6
    ) {
      const faultyFields = [];
    
      if (fullname === a1) faultyFields.push("Fullname");
      if (email === a2) faultyFields.push("Email");
      if (password === a3) faultyFields.push("Password");
      if (confirmPassword === a3a) faultyFields.push("ConfirmPassword");
      if (identity === a4) faultyFields.push("Identity Type");
      if (type === a5) faultyFields.push("Type");
      if (phoneNumber === a6) faultyFields.push("Phone Number");
    
      const errorMessage = `Please fill out all required fields:\n ${faultyFields.join('\n')}`;

      //alert(errorMessage);
      setErrorMessage(errorMessage);
      setIsRegistrationLoading(false);
      return;
    }

    if (password != confirmPassword) {

      const errorMessage = `Passwords don't match`;
      //alert(errorMessage);
      setErrorMessage(errorMessage);
      setIsRegistrationLoading(false);
      return;

    }
    

    //if (selectedFile != '' && attendanceType != 'Select' && selectedValues != '') 
    {
      try {
        const formData = new FormData();
        formData.append('fullname', fullname);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password_confirmation', confirmPassword);
        formData.append('identityType', identity);
        formData.append('type', type);
        formData.append('phoneNumber', phoneNumber);
        formData.append('identityFile', identityTypeFile);

  
        const response = await axios.post("https://gacsstage.zaptrance.ng/api/guest/register", formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          },
        });
  
        if (response.data.success) {
          //alert(JSON.stringify(response.data, null, 2));


          // Token exists in the response, store it
          const token = response.data.data.token;
          //alert("login token: " + token)
          localStorage.setItem('token', token);
          //alert("stored token: " + localStorage.getItem('token'))
          //alert("login: " + JSON.stringify(response.data.data, null, 2));

          const institutionLogo  = response.data.data.user.institution_logo;
          localStorage.setItem('institution_logo', institutionLogo );
          //alert("institution_logo: " + institutionLogo );

          const userType = response.data.data.user.type;
          //alert(JSON.stringify(response.data.data.user.type, null, 2));
          localStorage.setItem('user_type', userType);
          //alert("userType: " + userType);
          //alert(userType);

          const userId = response.data.data.user.id;
          //alert(JSON.stringify(response.data.data.user.type, null, 2));
          localStorage.setItem('user_id', userId);

          const userName = response.data.data.user.fullname;
          //alert(JSON.stringify(response.data.data.user.type, null, 2));
          localStorage.setItem('user_name', userName);

          const userEmail = response.data.data.user.email;
          //alert(JSON.stringify(response.data.data.user.type, null, 2));
          localStorage.setItem('user_email', userEmail);

          //const institutionName  = response.data.data.user.fullName;
          localStorage.setItem('institution_name', userType);//institutionName );

          setRegistrationStatus("Registration Successful");
          setIsModalOpen(true);
          
          
          clearFields();
        } else {
          //alert("f1");

          setRegistrationStatus("Registration Failed");
          setIsModalOpen(true);
          
          setErrorMessage(response.data.message + ": " + response.data.data);
          
        }
        setIsRegistrationLoading(false);
      } 
      
      // catch (error) {
      //   alert("f2");

      //   setRegistrationStatus("Registration Failed");

      //   setErrorMessage("Registration Failed");
      //   setIsRegistrationLoading(false);
      //   // Handle request error, e.g., display an error message to the user

      //   if (error.response && error.response.data && error.response.data.data) {
      //     const dataKeys = Object.keys(error.response.data.data);
      
      //     if (dataKeys.length > 0) {
      //       // Construct an error message from the data keys
      //       const errorMessage = dataKeys
      //         .map(key => {
      //           const messages = error.response.data.data[key];
      //           //alert(messages);
      //           //return `${key}: ${messages.join(', ')}`;
      //           //return `${messages.join(', ')}`;
      //           //return `${messages}`;
      //         })
      //         .join('\n');
      
      //         setErrorMessage(errorMessage);
      //     } else {
      //       // If there are no data keys, set a generic error message
      //       setErrorMessage('An error occurred during registration.');
      //     }
      //   } else {
      //     // Handle other error cases if needed
      //     setErrorMessage('An error occurred during registration.');
      //   }
      // }
      catch (error) {
        setRegistrationStatus("Registration Failed");
        setIsRegistrationLoading(false);
        let errorMessage = "Registration Failed\n";
        
        if (error.response && error.response.data && error.response.data.data) {
            const data = error.response.data.data;
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const messages = data[key];
                    errorMessage += `${key}: ${messages.join('\n')}\n`;
                }
            }
        } else {
            errorMessage += "An error occurred during registration.";
        }
    
        setErrorMessage(errorMessage);
    }
    



    }     
  }

  const handleDropdownIdentityChange = (event) => {
    const selectedValue = event.target.value;
  setIdentityType(selectedValue);
  //
  //alert(selectedValue);
  };

  const handleDropdownTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
  //
  //alert(selectedValue);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setPhoneNumber(value);
  };


  return (
    <div>
      <Header />
      <div style={pageStyle}>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            <div className="flex flex-col justify-center px-6 pt-2">
              <h1 style={{ fontSize: '36px', fontWeight: 'bold' }} className="py-2">
                Registration
              </h1>
              <p className="text-l  py-2">
                Complete the following information to get registered.
              </p>
            </div>
            <div className="justify-center p-6">
            <div className="" style={formContainerStyle}>
                  <form className="max-w-[500px] w-full mx-auto p-8 px-8 bg-white rounded-2xl ">
                    {/* <h2 className="text-2xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl text-black font-bold pb-8">
                      Register
                    </h2> */}
                    <div className="flex flex-col pb-2 ">
                      <label>Fullname</label>
                      <input
                        style={{ backgroundColor: '#EDF2F6' }}
                        className="rounded-l mt-2 p-2 focus"
                        type="text"
                        placeholder={fullname}
                        //value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col py-2">
                      <label>Phone Number</label>
                      <input
                        style={{ backgroundColor: '#EDF2F6' }}
                        className="rounded-l mt-2 p-2 focus"
                        //type='number'
                        type='text'
                        inputMode='numeric'
                        pattern='[0-9]*'
                        placeholder={phoneNumber}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                      />
                    </div>
                    <div className="flex flex-col py-2">
                      <label>Email</label>
                      <input
                        style={{ backgroundColor: '#EDF2F6' }}
                        className="rounded-l mt-2 p-2 focus"
                        type="text"
                        placeholder={email}
                        //value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col py-2">
                      <label>Password</label>
                      <div className="relative">
                        <input
                          style={{ backgroundColor: '#EDF2F6' }}
                          className="rounded-l mt-2 p-2 focus w-full"
                          type={passwordVisible ? 'text' : 'password'}
                          //pattern='[0-9]*'
                          placeholder={password}
                          //value={phoneNumber}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-2">
                          <IconButton tabIndex="-1"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </div>
                      </div>
                      
                    </div>
                    <div className="flex flex-col py-2">
                      <label>Confirm Password</label>
                      <div className="relative">
                      <input
                        style={{ backgroundColor: '#EDF2F6' }}
                        className="rounded-l mt-2 p-2 focus w-full"
                        type={passwordVisible ? 'text' : 'password'}
                        //pattern='[0-9]*'
                        placeholder={confirmPassword}
                        //value={phoneNumber}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-2">
                          <IconButton tabIndex="-1"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </div>
                      </div>
                    </div>
                    <div className="flex flex-col py-2 ">
                      <label>Identity Type:</label>
                      <select 
                      className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
                      onChange={(e) => handleDropdownIdentityChange(e)}
                      >
                        <option value="Select">Select</option>
                        <option value="cac">CAC</option>
                        <option value="driver_licence">Driver's License</option>
                        <option value="nin">NIN</option>
                        <option value="voters_card">Voters Card</option>
                        <option value="passport">Passport</option>
                      </select>
                    </div>
                    <div className="flex flex-col py-2 ">
                      <label>Type:</label>
                      <select 
                      className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
                      onChange={(e) => handleDropdownTypeChange(e)}
                      >
                        <option value="Select">Select</option>
                        <option value="individual">Individual</option>
                        <option value="organization">Organization</option>
                      </select>
                    </div>
                    <div className="flex flex-col py-2">
                      <label>Means of Identification:</label>
                      <input
                      className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
                      type="file"
                      accept="image/*"
                      onChange={(e) => 
                        {
                          setIdentityTypeFile(e.target.files[0]);
                          handlePreviewChange(e);
                          
                        }
                      }
                        
                      ref={fileInputRef}
                      />
                      
                      <div className='flex justify-center'>
                        <img className="w-64 h-36 object-scale-down mt-2 pb-4 " src={preview} alt="" />
                      </div>
                    
                    </div>
                    
                    
                    
                    
                    <div className='text flex flex-col justify-center' style={{  }}>
                      {errorMessage.split('\n').map((line, index) => (
                        <p key={index} className='text-center mb-' style={{ color: '#990000', fontSize: '12px' }}>
                          {line}
                        </p>
                      ))}
                    </div>

                    <div style={buttonContainerStyle} className="pt-8 flex flex-col items-center">
                      <Link className="pb-2" >
                        <button className="rounded-button " style={buttonStyle} onClick={handleRegistration}> 
                          


                          {isRegistrationLoading ? (
                      <LoadingScreen />
                    ) : (
                      'Register'
                    )}
                        </button>
                      </Link>

                      <Link className="" to="/login">
                        <button className="rounded-button" style={buttonStyle} >
                        Login
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
            </div>
          </div>

          <RegistrationModal
              isOpen={isModalOpen}
              onRequestClose={closeAddModal}
              registrationStatus={registrationStatus}
              registrationError={errorMessage}
            />

          <Footer />
        </div>
      </div>
    </div>
  );
}
