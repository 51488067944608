import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import InstitutionsSideNav from './InstitutionsSideNav';
import HeaderInstitutions from './HeaderInstitutions';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';


import FilterIcon from '@mui/icons-material/FilterListOutlined';
import AddIcon from '@mui/icons-material/Add';

import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BusinessIcon from '@mui/icons-material/Business';



const InstitutionsDashboard = () => {

  // const [isSideNavVisible, setIsSideNavVisible] = useState(true);

  
  const navigate = useNavigate();

  // Define state variables to store data
  // const [excel, setExcel] = useState([]);
  // const [images, setImages] = useState([]);
  // const [type, setType] = useState([]);
  // const [program, setProgram] = useState([]);

  const [noOfCertificates, setNoOfCertificates] = useState('');
  const [noOfPrograms, setNoOfPrograms] = useState('');

  let token = localStorage.getItem('token');
  useEffect(() => {
    // setIsSideNavVisible(false);
    //alert(localStorage.getItem('user_type'));

    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/login');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution-admin/index', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        //setNoOfUsers(response.data.data.users);
        //setNoOfInstitutions(response.data.data.institutions);
        setNoOfCertificates(response.data.data.numberOfCerts);
        setNoOfPrograms(response.data.data.numberOfPrograms);
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderInstitutions />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Dashboard
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#FFE5D3' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfCertificates}
                      </h1>
                      <div className='flex' ><div><CardMembershipIcon className='mr-4'/></div>Certificates generated</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#E2FBD7' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfPrograms}
                      </h1>
                      <div className='flex' ><div><LayersIcon className='mr-4'/></div>Accredited Programs</div>
                  </div>
                  {/* <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#DAD7FE' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfInstitutions}
                      </h1>
                      <div className='flex' ><div><BusinessIcon className='mr-4'/></div>Institutions</div>
                  </div> */}
              </div>
              {/* <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Recent Activities</div>
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      <div className="flex justify-between my-2 pb-2">
                      <div className='text-xl pb-2'>Activities</div>
                     
                      <div className="flex items-center">
                          <input
                              type="text"
                              placeholder="Search"
                              className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
                          />
                      </div>
                      <div className="flex items-center  bg-gray-300 rounded-lg px-4">
                        <FilterIcon className="text-gray-500 cursor-pointer mr-2" />
                          <span className="text-gray-500">Filter</span>                                    
                      </div>
                  </div>
                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Activities
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Date
            </th>
            
          </tr>
        </thead>
        
      </table>
    </div>
                  </div>
              </div> */}
          </div>
      </div>
  </div>
    );
}

export default InstitutionsDashboard;
