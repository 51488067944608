import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderZaptrance from './HeaderZaptrance';
import ZaptranceSideNav from './ZaptranceSideNav';
import Modal from 'react-modal';

import axios from 'axios';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import UpdateModal from '../landing/modals/UpdateModal';

import LoadingScreen from '../../LoadingScreen';


const ZaptranceUserDetail = () => {
  const navigate = useNavigate();
  
  // Define state variables to store data
  //const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  
  //const [programTypes, setProgramTypes] = useState([]);

  const [registrationErrorMessage, setRegistrationErrorMessage] = useState(null);

  //const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [errorMessage, setErrorMessage] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState(null);

  //
  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);
  const openAddProgramModal = () => {
    setIsAddProgramModalOpen(true);
  };
  const closeAddProgramModal = () => {
    setIsAddProgramModalOpen(false);
  };


  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);
  const openAddPaymentModal = () => {
    setIsAddPaymentModalOpen(true);
  };
  const closeAddPaymentModal = () => {
    setIsAddPaymentModalOpen(false);
  };


  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const openAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };
  const closeAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };
  //

  //let params = useParams();
  const { user, additionalVariable } = useParams();
  const parsedUser = JSON.parse(decodeURIComponent(user));
  //alert("parsedUser: " + parsedUser.id);





  const [cIuserFullname, setCIuserFullname] = useState('');
  const [cIuserInstitution, setCIuserInstitution] = useState('');
  const [cIuserInstitutionId, setCIuserInstitutionId] = useState('');
  const [cIuserEmail, setCIuserEmail] = useState('');
  const [cIuserTypeName, setCIuserTypeName] = useState('');
  const [cIuserStatus, setCIStatus] = useState('');

  const [cIuserTypeId, setCIuserTypeId] = useState('');

  


 
  const clearFields = () => {

  }

  //let user_type = localStorage.getItem('user_type');
  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);


  
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  // const [showDialog, setShowDialog] = useState(false);


  const [currentUser, setCurrentUser] = useState([]);


  
  // Function to close the modal
  const closeAddModal = () => {
    setIsUpdateModalOpen(false);
    if (registrationStatus == "Updated Successfully") {
      navigate("/zaptrance-users");
    } else {
      
    }
    
  };

    // Function to validate email format
    const isValidEmail = (email) => {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
  };




//parsedUser.id, cIuserFullname, cIuserInstitution, cIuserEmail, cIuserType, cIuserStatus
  const hitUpdateEndpoint  = async (value1, value2, value3, value4, value5, value6, value7 ) => { 

// let user_type_id = 0;
// if (user_type == 'zaptrance' ){ user_type_id = "1";}
// if (user_type == 'nbte' ){user_type_id = "2";}
// if (user_type == 'rector' ){user_type_id = "3";}
// if (user_type == 'registrar' ){user_type_id = "4";}
// if (user_type == 'institution admin' ){user_type_id = "5";}

    // alert(
    //   "fullname: " + cIuserFullname + "\n" + 
    //   "institutionId: " + value7 + "\n" + // cIuserInstitutionId, //'@Auwal provide this in /admin/users response' + "\n" + 
    //   "institution: " + cIuserInstitution + "\n" + 
    //   "email: " + cIuserEmail + "\n" + 
    //   "usertypeId: " + cIuserTypeId + "\n" +//'@Auwal provide this in /admin/users response'  + "\n" + 
    //   "usertype: " + cIuserTypeName  + "\n" + 
    //   "status: " + cIuserStatus
    // );

    if (!isValidEmail(cIuserEmail)) {
      //alert('Invalid email address');
      setRegistrationStatus("");
      setErrorMessage("Enter a valid email address");
      setIsUpdateModalOpen(true);
      return;
    }

    if (cIuserStatus == 'Select') {
      //alert("Invalid status selection");
      setRegistrationStatus("");
      setErrorMessage("Select a valid status");
      setIsUpdateModalOpen(true);
      return;
    }

     //return;

    const formData = new FormData();
    formData.append('userId', value1);
    formData.append('fullName', cIuserFullname);
    formData.append('institution', value7);
    //formData.append('email', value4);
    formData.append('type', cIuserTypeId);
    formData.append('status', value6);
    

    setIsUpdateLoading(true);
    try {
      // alert(`here: ${params.id}`);
      //console.log(value1, formData)
      const response = await axios.patch(`https://gacsstage.zaptrance.ng/api/admin/users/${value1}`, formData, {
        headers: {
          'Content-Type': 'application/json', //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          Authorization: `Bearer ${token}`,
        },
      });

      setIsUpdateLoading(false);
      // Check if the request was successful
      if (response.data.success) {
        const data = response.data.data;

        //alert("Updated Successfully");
        
        setRegistrationStatus("Updated Successfully");
        setIsUpdateModalOpen(true);
        
        //setIsDataLoading(false);

        handleData();
      } else {
        // Handle the case where the request was not successful
        //console.error('Request failed:' + response.data.message);
        //setIsDataLoading(false);

        alert('Request failed:' + response.data.message);

        setErrorMessage("Update Failed");
        setIsUpdateModalOpen(true);

        //alert("notok1");
      }
    } catch (error) {
      setIsUpdateLoading(false);
      //alert(error);
      //console.error('Request error:'+ error);
      //setIsDataLoading(false);
      // Handle request error, e.g., display an error message to the user

      alert('Request error:' + error);
      //alert("notok2" + error);
    }
  };



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get("https://gacsstage.zaptrance.ng/api/admin/users/" + parsedUser.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("user: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setCurrentUser(response.data.data);


        
        setCIuserFullname(response.data.data.fullName);
        setCIuserInstitution(response.data.data.institution);
        setCIuserInstitutionId(response.data.data.institution_info.id);
        setCIuserEmail(response.data.data.email);
        setCIuserTypeName(response.data.data.type);
        setCIStatus(response.data.data.status);


        if (setCIuserTypeName == 'zaptrance' ){ setCIuserTypeId("1");}
        else if (setCIuserTypeName == 'nbte' ){setCIuserTypeId("2");}
        else if (setCIuserTypeName == 'rector' ){setCIuserTypeId("3");}
        else if (setCIuserTypeName == 'registrar' ){setCIuserTypeId("4");}
        else if (setCIuserTypeName == 'admin' ){setCIuserTypeId("5");}
        else {setCIuserTypeId("0");}




      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  const handleDropdownStatusChange = (event) => {
    const selectedValue = event.target.value;
    setCIStatus(selectedValue);
  //
  // alert(selectedValue);
  };

  
  const handleDropdownUserTypeChange = (event) => {
    const selectedValue = event.target.value;
    setCIuserTypeName(selectedValue);

    if (selectedValue == 'zaptrance' ){ setCIuserTypeId("1");}
        else if (selectedValue == 'nbte' ){setCIuserTypeId("2");}
        else if (selectedValue == 'rector' ){setCIuserTypeId("3");}
        else if (selectedValue == 'registrar' ){setCIuserTypeId("4");}
        else if (selectedValue == 'admin' ){setCIuserTypeId("5");}
        else {setCIuserTypeId("0");}

  };

  return (
    <div className="content flex-grow">
        <div className="inner-content">
          <HeaderZaptrance />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            {/* <div className='text-black p-4 flex text-xl'>
              <div><BusinessIcon className='mr-4'/>Payment: {currentPayment.name}</div>
            </div> */}

            
            <div>

                <div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>User Details</h2>
  </div>


  <div>
    <button
      onClick={()=> {
        navigate("/zaptrance-users");
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>


</div>


<div className="w-full px-8 pt-4">

      

<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
{/* <div className="flex flex-col py-2">
  <label>User Id:</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={parsedUser.id}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div> */}

<div className="flex flex-col py-2">
    <label>Fullname</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={cIuserFullname}
      readOnly={true}
      onChange={(e) => setCIuserFullname(e.target.value)}
    />
  </div>

  <div className="flex flex-col py-2">
    <label>Institution</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={cIuserInstitution}
      readOnly={true}
      onChange={(e) => setCIuserInstitution(e.target.value)}
    />
  </div>
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
  <label>Email:</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={cIuserEmail}
      readOnly={true}
      onChange={(e) => setCIuserEmail(e.target.value)}
    />
</div>

<div className="flex flex-col py-2">
    <label>Type</label>
        {additionalVariable == 'noEdit' ? 
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={cIuserTypeName}
      readOnly={true}
      onChange={(e) => setCIuserTypeName(e.target.value)}
    /> : 
    <select 
          className="mt-2 p-2 focus rounded-lg"
          value={cIuserTypeName}
          onChange={(e) => handleDropdownUserTypeChange(e)}
        >
          <option value="select">Select Type</option>
          <option value="zaptrance">zaptrance</option>
          <option value="nbte">nbte</option>
          <option value="rector">rector</option>
          <option value="registrar">registrar</option>
          <option value="admin">admin</option>
        </select>
    }
  </div>

  
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
{/* <div className="flex flex-col py-2">
  <label>Status</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={cIuserStatus}
      readOnly={true}
      onChange={(e) => setCIStatus(e.target.value)}
    />
</div> */}

<div className="flex flex-col py-2">
    <label>Status</label>
    {additionalVariable == 'noEdit' ? 
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={cIuserStatus}
      readOnly={true}
      onChange={(e) => setCIStatus(e.target.value)}
    /> : 
    <select
              className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
              value={cIuserStatus}
              onChange={(e) => handleDropdownStatusChange(e)}>
                <option value="Select">Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
            </select>
    }
  </div>

</div>


 

  


  <div className="text-center pt-2">

  {additionalVariable == 'noEdit' ? <div></div> : 
  <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
    onClick={() => {
      if (!isUpdateLoading) {
        hitUpdateEndpoint(parsedUser.id, cIuserFullname, cIuserInstitution, cIuserEmail, cIuserTypeName, cIuserStatus, cIuserInstitutionId);
      }
    }}
      >
        
              {isUpdateLoading ? <LoadingScreen /> : 'Update'}
        </button>}




      <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
    onClick={() => {
      //onRequestClose();
      clearFields();
      setRegistrationErrorMessage('');
      //alert(institution.id);
      navigate("/zaptrance-users");
    }}
      >
        
              Okay
        </button>


        
      </div>


</div>
              

              </div>
            
            
            
              <UpdateModal
              isOpen={isUpdateModalOpen}
              onRequestClose={closeAddModal}
              registrationStatus={registrationStatus}
              registrationError={errorMessage}
            />

          </div>
        </div>
      </div>
  );
};

export default ZaptranceUserDetail;
