import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import InstitutionsSideNav from './InstitutionsSideNav';
import HeaderInstitutions from './HeaderInstitutions';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import FilterIcon from '@mui/icons-material/FilterListOutlined';
import AddIcon from '@mui/icons-material/Add';

const InstitutionsAccreditedPrograms = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [programs, setPrograms] = useState([]);
  //const [programTypes, setProgramTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

   // Function to open the modal
   const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  // Function to close the modal
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  // Function to add an institution (you can implement this)
  const handleAddUser = (userName) => {
    // Implement the logic to add an institution here

    // Close the modal when the operation is complete
    closeAddModal();
  };



  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    setLoading(true);
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/programs', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        setLoading(false);

        // Store the retrieved data in state variables
        setPrograms(response.data.data);
        
        //await getProgramTypes();

      } else {
        setLoading(false);

        // Handle the case where the request was not successful
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      setLoading(false);
      
      console.error('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  let count = 1;


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderInstitutions />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Accredited Programs
              </div>
              



              
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      

                      <div className="flex justify-between my-2 pb-2">
                      <div className='text-xl pb-2'>Activities</div>
                     
                      <div className="flex items-center">
                          <input
                              type="text"
                              placeholder="Search"
                              className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
                          />
                      </div>
                      <div className="flex items-center  bg-gray-300 rounded-lg px-4">
                        <FilterIcon className="text-gray-500 cursor-pointer mr-2" />
                          <span className="text-gray-500">Filter Search</span>                                    
                      </div>
                  </div>

                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              SN
            </th>
            {/* <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Id
            </th> */}
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program Name
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Status
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program Type
            </th>

            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Certificates
            </th>
            
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {programs.map((program) => (
            <tr key={program.id}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {count++}
              </td>
              {/* <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {program.id}
              </td> */}
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {program.programName}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {program.status}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {program.type}
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {program.certificates}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default InstitutionsAccreditedPrograms;
