import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderZaptrance from './HeaderZaptrance';
import ZaptranceSideNav from './ZaptranceSideNav';
import Modal from 'react-modal';

import axios from 'axios';

import AddInstitutionModal from './modals/AddInstitutionModal';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/ModeOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import FilterIcon from '@mui/icons-material/FilterListOutlined';


import AddProgramToInstitutionModal from './modals/AddProgramToInstitutionModal';
import AddPaymentToInstitutionModal from './modals/AddPaymentToInstitutionModal';
import AddUserToInstitutionModal from './modals/AddUserToInstitutionModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';


const itemsPerPage = 500;

const ZaptrancepaymentDetail = () => {
  const navigate = useNavigate();
  
  // Define state variables to store data
  //const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  
  //const [programTypes, setProgramTypes] = useState([]);

  const [registrationErrorMessage, setRegistrationErrorMessage] = useState(null);

  //const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  //
  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);
  const openAddProgramModal = () => {
    setIsAddProgramModalOpen(true);
  };
  const closeAddProgramModal = () => {
    setIsAddProgramModalOpen(false);
  };


  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);
  const openAddPaymentModal = () => {
    setIsAddPaymentModalOpen(true);
  };
  const closeAddPaymentModal = () => {
    setIsAddPaymentModalOpen(false);
  };


  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const openAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };
  const closeAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };
  //

  //let params = useParams();
  const { payment } = useParams();
  const parsedPayment = JSON.parse(decodeURIComponent(payment));
  //alert("parsedInstitution: " + parsedInstitution.id);


 
  const clearFields = () => {

  }

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);


  
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  // const [showDialog, setShowDialog] = useState(false);


  const [currentPayment, setCurrentPayment] = useState([]);





  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get("https://gacsstage.zaptrance.ng/api/admin/payments/" + parsedPayment.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("payment: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setCurrentPayment(response.data.data);
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  

  return (
    <div className="content flex-grow">
        <div className="inner-content">
          <HeaderZaptrance />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            {/* <div className='text-black p-4 flex text-xl'>
              <div><BusinessIcon className='mr-4'/>Payment: {currentPayment.name}</div>
            </div> */}

            
            <div>

                <div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>Payment Details</h2>
  </div>


  <div>
    <button
      onClick={()=> {
        navigate("/zaptrance-payment");
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>


</div>


<div className="w-full px-8 pt-4">

      

<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
{/* <div className="flex flex-col py-2">
  <label>Payment Id:</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={currentPayment.id}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div> */}

<div className="flex flex-col py-2">
    <label>Payment Institution</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={currentPayment.institution}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>

  <div className="flex flex-col py-2">
    <label>Payment Committed By</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={currentPayment.committed_by}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
  <label>Date:</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={currentPayment.date}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div>

<div className="flex flex-col py-2">
    <label>Status</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={currentPayment.status}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>

  
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
  <label>Date Paid</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={currentPayment.date_paid}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div>

<div className="flex flex-col py-2">
<label>Teller Number</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={currentPayment.teller_number}
      readOnly={true}
      length={30}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>

  <div className="flex flex-col py-2">
  <label>Expiry Date</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={currentPayment.expiry_date}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>
</div>
 

  


  <div className="text-center pt-2">
      <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
    onClick={() => {
      //onRequestClose();
      clearFields();
      setRegistrationErrorMessage('');
      //alert(institution.id);
      navigate("/zaptrance-payment");
    }}
      >
        
              Okay
        </button>
      </div>


</div>
              

              </div>
            
            
            
           

          </div>
        </div>
      </div>
  );
};

export default ZaptrancepaymentDetail;
