import React, { useState } from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import LoadingScreen from '../../../LoadingScreen';


import SimpleModal from './SimpleModal';


import { faClock, faMapMarker } from '@fortawesome/free-solid-svg-icons';


const customModalStyles = {
  content: {
    width: '80%', 
    //height: '90%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '1%',
  },
};

const AddUserToInstitutionModal = ({ isOpen, onRequestClose, institution }) => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  

  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);

  const [registrationError, setRegistrationErrorMessage] = useState('');

  const [isNetworkLoading, setIsNetworkLoading] = useState(false);


  const [errorMessage, setErrorMessage] = useState('');

  
    // Function to close the modal
    const closeSimpleModal = () => {
      setIsSimpleModalOpen(false);
      clearFields();
      if (registrationStatus == "Registration Successful") {
        //navigate("/");
        onRequestClose();
      }
      
    };

  const clearFields = () => {
    setFullname('');
    setEmail('');
    setType('');  
    
  
    setRegistrationStatus(null);
    //setIsSimpleModalOpen(false);
  
    //setRegistrationErrorMessage('');
  }


   // Function to validate email format
   const isValidEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

  const hitAddUserToInsitutionEndpoint = async () => {

    
     // Validate email before proceeding
     if (!isValidEmail(email)) {
      alert('Invalid email address');
      //setRegistrationStatus("Invalid email address");
      //setIsModalOpen(true);
      return;
    }


    setRegistrationErrorMessage('');
    setIsNetworkLoading(true);

    // alert(
    //   'fullname: ' + fullname + "\n" +
    //   'email: ' + email + "\n" +
    //   'type: ' + type + "\n\n" +
    //   'institution: ' + institution.id + "\n" +
    //   "https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/users"
    // );

    if (
      fullname  == ''  ||
      email == '' ||
      type == ''
      ) {
        
        const faultyFields = [];
        if (fullname == '') faultyFields.push("Fullname");
        if (email == '') faultyFields.push("Email");
        if (type == '') faultyFields.push("Select Type");
        const errorMessage = `Please fill out all required fields: ${faultyFields.join(', ')}`;
        setRegistrationErrorMessage(errorMessage);

      //alert("Please select all fields.");
      setIsNetworkLoading(false);
      return;
    }


    try {
      const token = localStorage.getItem('token')
      //alert(token);

      const formData = new FormData();
      formData.append('fullname', fullname);
      formData.append('email', email);
      formData.append('type', type);

      // Append the actual file to the formData

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/users", formData, {
        headers: {
          //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        //alert("ok");
        setRegistrationStatus("Registration Successful");
        setIsSimpleModalOpen(true);
        //console.log('Registration successful:' + response.data.message);


        //clearFields();

        // // Close the modal
        // alert("Registration Successful");
        // onRequestClose();
        // //onAddUser();

        // You can also redirect the user to a confirmation page or do any other actions.
      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Registration Failed");
        setIsSimpleModalOpen(true);
        //alert('Registration failed:' + response.data);
        setRegistrationErrorMessage(response.data);
      }
    } catch (error) {
      setIsNetworkLoading(false);
      //alert("not oka");

      setRegistrationStatus("Registration Failed");
      // setRegistrationErrorMessage("Registration Failed");
      

      
      // if (error.response && error.response.data && error.response.data.message) {
      //   // Print the specific error message received from the server
      //   //alert('Registration Failed:'+ error.response.data.message);
    
      //   // Check for validation errors related to the "type" field
      //   if (error.response.data.data && error.response.data.data.type) {
      //     //alert('Type error:'+ error.response.data.data.type[0]);
      //     setRegistrationErrorMessage(`Type error: ${error.response.data.data.type[0]}`);
      //   } else {
      //     // You can handle other validation errors similarly
      //     setRegistrationErrorMessage('An error occurred during registration.');
      //   }
      // } else {
      //   // Handle other error cases if needed
      //   //alert('An unexpected error occurred during registration.');
      //   setRegistrationErrorMessage('An unexpected error occurred during registration.');
      // }


      if (error.response && error.response.data && error.response.data.data) {
        const dataKeys = Object.keys(error.response.data.data);
    
        if (dataKeys.length > 0) {
          // Construct an error message from the data keys
          const errorMessage = dataKeys
            .map(key => {
              const messages = error.response.data.data[key];
              //return `${key}: ${messages.join(', ')}`;
              return `${messages.join(', ')}`;
            })
            .join('\n');
    
          setRegistrationErrorMessage(errorMessage);
        } else {
          // If there are no data keys, set a generic error message
          setRegistrationErrorMessage('An error occurred during registration.');
        }
      } else {
        // Handle other error cases if needed
        setRegistrationErrorMessage('An error occurred during registration.');
      }


      setIsSimpleModalOpen(true);

    }

  };


  const handleDropdownTypeChange = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
    //alert(selectedValue);
  };





  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Institution Modal"
      style={customModalStyles}
    >
      




<div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>Add User To {institution.name} Institution</h2>
  </div>
  <div>
    <button
      //onClick={onRequestClose}
      onClick={() => {
        onRequestClose();
        clearFields();
        setRegistrationErrorMessage('');
        setIsSimpleModalOpen(false);
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>
</div>

<div className="w-full px-8 pt-4">

{/* <div className="flex flex-col py-2">
  <label>Institution Id: <strong>{institution.id}</strong></label>
</div>

<div className="flex flex-col py-2">
    <label>Institution Name: <strong>{institution.name}</strong></label>
  </div>
  <br /> */}


<div className="flex flex-col py-2">
          <label>Type</label>
          <select 
          className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
          // className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          onChange={(e) => handleDropdownTypeChange(e)}
        >
          <option value="0">Select Type</option>
          <option value="1">Zaptrance</option>
          <option value="2">Nbte</option>
          <option value="3">Rector</option>
          <option value="4">Registrar</option>
          <option value="5">Institution Admin</option>
        </select>
        </div>
        
  <div className="flex flex-col py-2">
          <label>Fullname</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Enter Fullname"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
        </div>

        <div className="flex flex-col py-2">
          <label>Email</label>
          <input
            style={{ backgroundColor: '#EDF2F6', width: '100%' }}
            className="rounded-lg mt-2 p-2 focus"
            type="text"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        

  


  <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
  <p className='text-center mb-4 text-red-500'
  style={{ 
    //color: 'red',//'#990000', 
    fontSize: '12px' }}>{registrationError}</p>
</div>





  <div className="text-center pt-2">
      <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
      onClick={hitAddUserToInsitutionEndpoint}
      >
        {isNetworkLoading ? (
                <LoadingScreen />
              ) : (
                'Add'
              )}
        </button>
      </div>


</div>

<SimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
              registrationError={registrationError}
              message='User'
/>

    </Modal>
  );
};

export default AddUserToInstitutionModal;
