import React, { useState, useEffect } from 'react';

import ellipse from '../../../assets/ellipse.png';

export default function HeaderZaptrance() {

    const [storedName, setStoredName] = useState('');

    useEffect(() => {
        const storedName = localStorage.getItem('institution_name');
        setStoredName(storedName);
      }, []);

    return (
        <div className='flex justify-between items-center px-20 py-4'>
            <div style={{fontSize: '18px', fontWeight: 'bold' }}>
                GACS PLATFORM
            </div>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                <div>
                    
                </div>
                <div style={{ padding: '0 10px'}}>
                {storedName}
                </div>
                <div>
                    <img
                                src={ellipse}
                                alt="Landing Page"
                                style={{ width: '20px', height: '20px' }}
                            />
                </div>
            </div>
        </div>
    )
}

