import React from 'react';
import logo from '../assets/logo.png'

import { NavLink } from 'react-router-dom';



export default function Header() {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 items-center container mx-auto'>
            <div className='flex items-center'>
                <img
                    src={logo}
                    alt='Logo'
                    //className='w-10 h-10 mr-2' // Adjust the width and height
                />
            </div>
            
            <div className='text-right'>
                {/* Add other header content here */}
            </div>
        </div>
    )
}
