import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import GuestSideNav from './GuestSideNav';
import HeaderGuest from './HeaderGuest';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

export default function GuestRequestLists() {

  const navigate = useNavigate();

  // Define state variables to store data
  const [requests, setRequests] = useState([]);



  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(requests ? requests.length : 0);

   //sorting
   const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
   const [sortColumn, setSortColumn] = useState('fullname'); // Default column to sort
   //sorting



  


  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredRequests = requests.filter((request) =>
  request.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
  request.certificate_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
  request.transaction_id.toLowerCase().includes(searchQuery.toLowerCase())
  );



  
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  // const [showDialog, setShowDialog] = useState(false);


  const [currentRequest, setCurrentRequest] = useState([]);



  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/guest/certificates/requests', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("guest requests: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setRequests(response.data.data);
        setTotalItems(response.data.data.length);
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredRequests = filteredRequests ? filteredRequests.slice(indexOfFirstItem, indexOfLastItem) : [];
  let countRequests = indexOfFirstItem + 1;


  const handleRowClick = (request, e) => {
    
    //alert(JSON.stringify(user, null, 2));
    //alert(registration.id);

    setSelectedRow(request);
    setSelectedRequest(request);
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    const requestString = JSON.stringify(request);
    const additionalVariable = 'noEdit';
    navigate(`/guest-request-lists/${encodeURIComponent(requestString)}/${additionalVariable}`);

  };

    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderGuest />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>My Requests
              </div>
              
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      {/* <div className='text-xl pb-2'>Activities</div> */}

                      <div className="flex justify-between my-2 pb-2 ">
<div className="flex items-center">
<div className="text-xl">Requests List</div>
</div>
<div className="flex items-center">
<input
type="text"
placeholder="Search"
className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
onChange={handleSearchChange}
/>


</div>
</div>


                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              S/N
            </th>
            
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Certificate Number
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Transaction Id
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-center text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Status
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Amount
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Created Date
            </th>
            
          </tr>
        </thead>
        <tbody>
          {currentFilteredRequests.map((request) => (
            <tr key={request.id} onClick={(e) => handleRowClick(request, e)} style={{ cursor: "pointer" }}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {countRequests++}{/* {request.id} */}
              </td>                        
              
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {request.certificate_number}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {request.transaction_id}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 " >
                <div className="text-center px-2 py-1" style={{ 
                  borderRadius: '10px', 
                  color: request.status === 'verified' ? 'green' : 'red',
                  background: '#eeeeee' }}>{request.status}
                  </div>
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {request.amount}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {request.created_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
              {/* Pagination Controls */}
<div className="flex justify-center mt-4">
<button
onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
disabled={currentPage === 1}
className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Previous
</button>
<button
onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Next
</button>
</div>
          </div>
      </div>
  </div>
    );
  
}
