import React from 'react';

const PaymentModal = ({ isOpen, onClose, paymentUrl }) => {
  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <iframe
              title="Payment Page"
              src={paymentUrl}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentModal;
