import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import HeaderRectorAndRegistrar from './HeaderRector';

import axios from 'axios';

import PersonIcon from '@mui/icons-material/Person';

import logo from '../../../assets/zlogo.png';

const RectorAndRegistrarProfile = () => {
  
  const navigate = useNavigate();

  const [profile, setProfile] = useState([]);

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/profile', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        setProfile(response.data.data);

      } else {
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRectorAndRegistrar />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><PersonIcon className='mr-4'/></div>Rector Profile
              </div>

                                                     
<div className="w-full px-8 pt-4">



<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">


<div className="flex flex-col py-2 ">

<div className="flex flex-col py-2">
<label>Profile Picture</label>
<img className="w-64 h-36 object-scale-down pb-4 " src={ logo } alt="" /> 
</div>
</div>



<div className="flex flex-col py-2">
<label>Profile Name</label>
<input
style={{ backgroundColor: '#EDF2F6', width: '100%' }}
className="rounded-lg mt-2 p-2 focus"
type="text"
//placeholder={cIcurrentInstitutionName}
value={profile.name}
//readOnly={additionalVariable === 'noEdit'}
//onChange={(e) => setCIcurrentInstitutionName(e.target.value)}
/>
</div>




<div className="flex flex-col py-2">
<label>Profile Status</label>
<input
style={{ backgroundColor: '#EDF2F6', width: '100%' }}
className="rounded-lg mt-2 p-2 focus"
type="text"
//placeholder={cIcurrentInstitutionName}
value={profile.status}
//readOnly={additionalVariable === 'noEdit'}
//onChange={(e) => setCIcurrentInstitutionName(e.target.value)}
/>
{/* {
<select
    className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
    //onChange={(e) => handleDropdownStatusChange(e)}
    >
      <option value="Select">Select</option>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
  </select>
} */}
</div>
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
<label>Email:</label>
<input
style={{ backgroundColor: '#EDF2F6', width: '100%' }}
className="rounded-lg mt-2 p-2 focus"
type="text"
//placeholder={cIcurrentInstitutionEmail}
value={profile.email}
//readOnly={additionalVariable === 'noEdit'}
//onChange={(e) => setCIcurrentInstitutionEmail(e.target.value)}
/>
</div>

<div className="flex flex-col py-2">
<label>OnBoardingYear</label>
<input
style={{ backgroundColor: '#EDF2F6', width: '100%' }}
className="rounded-lg mt-2 p-2 focus"
type="text"
//placeholder={cIcurrentInstitutionOnBoardingYear}
value={profile.onBoardingYear}
//readOnly={additionalVariable === 'noEdit'}
//onChange={(e) => setCIcurrentInstitutionOnBoardingYear(e.target.value)}
/>
</div>

<div className="flex flex-col py-2">
<label>Address</label>
<input
style={{ backgroundColor: '#EDF2F6', width: '100%' }}
className="rounded-lg mt-2 p-2 focus"
type="text"
//placeholder={cIcurrentInstitutionOnBoardingYear}
value={profile.address}
//readOnly={additionalVariable === 'noEdit'}
//onChange={(e) => setCIcurrentInstitutionOnBoardingYear(e.target.value)}
/>
</div>


</div>

</div>
          
              
          </div>
      </div>
  </div>
    );
}

export default RectorAndRegistrarProfile;
