import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderRector from './HeaderRector';

import axios from 'axios';

import LayersIcon from '@mui/icons-material/Layers';

import { format, parseISO } from 'date-fns';

const RectorPayments = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [payments, setPayments] = useState([]);

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/payments', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setPayments(response.data.data);
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  let count = 1;

    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRector />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Payments
              </div>
              
              {/* <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Recent Payments</div> */}
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      <div className='text-xl pb-2'>Activities</div>
                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              SN
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Institution
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Commited By
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Date
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Date Paid
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Teller Number
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Expiry Date
            </th>
            
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {count++} {/*{payment.id} */}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {payment.institution}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {payment.committed_by}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {/* {payment.date} */}
                {payment.date.toString() != "0000-00-00" ? format(parseISO(payment.date), "yyyy-MM-dd HH:mm") : '0000-00-00'}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {/* {payment.date_paid} */}
                {payment.date_paid.toString() != "0000-00-00" ? format(parseISO(payment.date_paid), "yyyy-MM-dd HH:mm") : '0000-00-00'}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {payment.teller_number}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                {/* {payment.expiry_date} */}
                {payment.expiry_date.toString() != "0000-00-00" ? format(parseISO(payment.expiry_date), "yyyy-MM-dd HH:mm") : '0000-00-00'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default RectorPayments;
