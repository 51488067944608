import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import InstitutionsSideNav from './InstitutionsSideNav';
import HeaderInstitutions from './HeaderInstitutions';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import FilterIcon from '@mui/icons-material/FilterListOutlined';
import AddIcon from '@mui/icons-material/Add';

import SimpleModal from './modals/SimpleModal';

const InstitutionsCertificate = () => {
  
  const navigate = useNavigate();

  const [selectedProgramTypeIndexId, setSelectedProgramTypeIndexId] = useState("");
  const [selectedProgramIndexId, setSelectedProgramIndexId] = useState("");


 // const [programName, setProgramName] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [program, setProgram] = useState([]);
  const [selectedExcelFile, setSelectedExcelFile] = useState({});
  const [selectedZipFile, setSelectedZipFile] = useState({});
  const [certificates, setCertificates] = useState([]);

  const [loading, setLoading] = useState(true);


  const [certificateUploadErrorMessage, setCertificateUploadErrorMessage] = useState('');
  const [isNetworkLoading, setIsNetworkLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null);  


  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [registrationError, setRegistrationErrorMessage] = useState('');

  const closeSimpleModal = () => {
    setIsSimpleModalOpen(false);
    clearFields();
    if (registrationStatus == "Upload Certificate Successful") {
      //navigate("/");
      clearFields();
      //onRequestClose();
      getProgramsTypes();

    }
    
  };



  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
      getProgramsTypes();

    }
  }, [navigate]);




  const handleExcelFileChanged = (e) => {
    const file = e.target.files[0];
  if (file) {
    setSelectedExcelFile(file);
    //alert('File selected: ' + file.name);
    //alert(file.name);
  } else {
    alert('No excel file selected');
  }
  };

  const handleZipFileChanged = (e) => {
    const file = e.target.files[0];
  if (file) {
    setSelectedZipFile(file);
    //alert('File selected: ' + file.name);
    //alert(file.name);
  } else {
    alert('No zip file selected');
  }
  };

  const clearFields = () => {
    
    setSelectedProgramTypeIndexId("");
  setSelectedProgramIndexId("");


 // const [programName, setProgramName] = useState([]);
  setProgramTypes([]);
  setProgram([]);
  setSelectedExcelFile({});
  setSelectedZipFile({});

  setLoading(true);


  setCertificateUploadErrorMessage('');
  setIsNetworkLoading(false);
  setRegistrationStatus(null);  
  


  }

  
  const hitUploadCertificateEndpoint = async () => {
    const token = localStorage.getItem('token')
    //onAddInstitution(institutionName);
    //setInstitutionName('');

    setCertificateUploadErrorMessage('');
    setIsNetworkLoading(true);

    // alert(
    //   'type: ' + selectedProgramTypeIndexId +
    //     '\nprogram: ' + selectedProgramIndexId +
    //     '\nexcelFile: ' + selectedExcelFile +
    //     '\images: ' + selectedZipFile 
    // );


    // if (
    //   //institutionName  == ''  ||
    //   //onboardingYear == '' ||
    //   //email == '' ||
    //   //genType == ''
    //   ) {
    //   alert("Please select a type.");
    //   setIsNetworkLoading(false);
    //   return;
    // }

    try {
      const formData = new FormData();
      //formData.append('name', "institutionName");
      formData.append('type', selectedProgramTypeIndexId);
      formData.append('program', selectedProgramIndexId);

      // Append the actual file to the formData
      formData.append('excelFile', selectedExcelFile);
      formData.append('images', selectedZipFile);
      

      // Append the actual file to the formData
      //formData.append('paymentEvidence', selectedFile);

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/institution-admin/certificates", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          //"Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        //alert("Ok. " + response.data.message + ".");
        
        setRegistrationErrorMessage(response.data.message + ".");
        setRegistrationStatus("Upload Certificate Successful");
        setIsSimpleModalOpen(true);
        
        //setIsModalOpen(true);
        //console.log('Upload Certificate successful:' + response.data.message);
        //clearFields();


        // Close the modal
        //onAddPayment();

      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Upload Certificate Failed");
        setIsSimpleModalOpen(true);
        //setIsModalOpen(true);
        //console.error('Upload Certificate failed:' + response.data);

        setRegistrationErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsNetworkLoading(false);
      setRegistrationStatus("Upload Certificate Failed");
      //console.error('Upload Upload Certificate Failed:'+ error);
      // Handle request error, e.g., display an error message to the user

      setRegistrationStatus("Upload Certificate Failed");
      //console.error('Upload Certificate Failed:'+ error.message);

      // setRegistrationErrorMessage(error.message);
      

      

  if (error.response && error.response.data && error.response.data.data) {
    const dataKeys = Object.keys(error.response.data.data);
  
    if (dataKeys.length > 0) {
      // Construct an error message from the data keys
      // const errorMessage = dataKeys
      //   .map(key => {
      //     const messages = error.response.data.data[key];
      //     if (Array.isArray(messages)) {
      //       return messages.join(', ');
      //     } else {
      //       return messages;
      //     }
      //   })
      //   .join('\n');

       const errorMessage = dataKeys
       .map(key => {
         const messages = error.response.data.data[key];
         //alert(messages);
         //return `${key}: ${messages.join(', ')}`;
         //return `${messages.join(', ')}`;
         return `${messages}`;
       })
       .join('<br>');

  
        setCertificateUploadErrorMessage(errorMessage);
        setRegistrationErrorMessage(errorMessage);
    } else {
      // If there are no data keys, set a generic error message
      setCertificateUploadErrorMessage('An error occurred during certificate upload.');
      setRegistrationErrorMessage('An error occurred during certificate upload.');
    }
  } else {
    // Handle other error cases if needed
    setCertificateUploadErrorMessage('An error occurred during certificate upload.');
    setRegistrationErrorMessage('An error occurred during certificate upload.');
  }

  
  
  


  setIsSimpleModalOpen(true);
    }
  };


  const getProgramsTypes = async () => { 
    
    setLoading(true);
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/program_types', { //aftewrwards : {{url}}/institution/type/99c5937b-3ab0-4864-b47c-69220d0d782d/programs
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);

      if (response.data.success) {
        //alert("programTypes: " + JSON.stringify(response.data.data, null, 2));

        setProgramTypes(response.data.data);
        
      } else {
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);

      alert('Request error:'+ error);  
    }
  };

  const getProgramsOnType = async (pId) => {
    //alert("https://gacsstage.zaptrance.ng/api/institution/type/" + pId + "/programs");

    setLoading(true);
    try {
      const response = await axios.get("https://gacsstage.zaptrance.ng/api/institution/type/" + pId + "/programs", { //aftewrwards : {{url}}/institution/type/99c5937b-3ab0-4864-b47c-69220d0d782d/programs
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false); 
      if (response.data.success) {
        //alert("programs: " + JSON.stringify(response.data.data, null, 2));

        setProgram(response.data.data);
        
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false); 

      alert('Request error:' + error);
    }
  }

  const handleDropdownChange = async (index) => {
    //alert(programTypes[index].id + " --> " + programTypes[index].programName);
    setProgram([]);

    setSelectedExcelFile([]);
    setSelectedZipFile([]);

    try {
      setSelectedProgramTypeIndexId(programTypes[index].id);
      //alert(programTypes[index].id + " \n\n" + programTypes[index].name);


      await getProgramsOnType(programTypes[index].id);

    } catch {
      setSelectedProgramTypeIndexId("");
      //setProgramName("");
    } 
    
  };
  
  
  const handleProgramDropdownChange = async (index) => {
    try {
      //alert("programType: " + selectedProgramTypeIndexId
      
      //+ "\n" + "program:" + program[index].id);

      setSelectedProgramIndexId(program[index].id);
    } catch {
      setSelectedProgramIndexId("");
    } 
    
  };


  const downloadExcelFile = async () => {
    try {
      // Replace the file URL with the actual URL of your Excel file
      const excelFileUrl = "https://gacsstage.zaptrance.ng/sample_upload.xlsx";
  
      // Open the file URL in a new window or tab to initiate the download
      window.open(excelFileUrl, '_blank');
    } catch (error) {
      alert('Error downloading Excel file:'+ error);
    }
  };

  
  const genrateQrCodeAndCertificate = async () => {
    try {
      
    } catch (error) {
      alert('Error generating QR'+ error);
    }
  };


  const handleRowClick = (certificate, e) => {
    e.stopPropagation();
    // setSelectedRow(certificate);
    // setSelectedCertificate(certificate);

    // ------------------------------------
    //alert(JSON.stringify(institution, null, 2));
    //alert(registration.id);

    
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    const certificateBatchString = JSON.stringify(certificate);    
    const additionalVariable = 'noEdit';
    
    //navigate(`/zaptrance-institution-certificate-batch/${encodeURIComponent(institutionString)}/${additionalVariable}`);
    navigate(`/institutions-certificate-batch/${encodeURIComponent(certificateBatchString)}`);
  };

  
  const handleData = async () => { 
    
    setLoading(true);
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution-admin/certificates', { //aftewrwards : {{url}}/institution/type/99c5937b-3ab0-4864-b47c-69220d0d782d/programs
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);

      if (response.data.success) {
        //alert("setCertificates: " + JSON.stringify(response.data.data, null, 2));

        setCertificates(response.data.data);
        
      } else {
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);

      alert('Request error:'+ error);  
    }
  };
  


  const downloadQRCode = async (qrLink) => {
    try {
      const qrFileUrl = `https://gacsstage.zaptrance.ng/api/institution-admin/certificates/download/1/qr/${qrLink}`;
      //alert(qrFileUrl);
      
      const response = await axios.get(qrFileUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Set the response type to blob
      });
  
      // Create a temporary link and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.download = 'qr_codes.zip'; // Set the desired filename and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert('Error downloading QR file:'+ error);
    }
  };
  

  
  const downloadCertificates = async (certLink) => {
    try {
      const certFileUrl = `https://gacsstage.zaptrance.ng/api/institution-admin/certificates/download/1/certificate/${certLink}`;
      //alert(certFileUrl);

      const response = await axios.get(certFileUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Set the response type to blob
      });
  
      // Create a temporary link and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.download = 'cert_files.zip'; // Set the desired filename and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert('Error downloading Certificate File:'+ error);
    }
  };
 




  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(certificates ? certificates.length : 0);
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredCertificates = certificates.filter((certificate) =>
  certificate.date.includes(searchQuery.toLowerCase())
  //|| certificate.batch.includes(searchQuery.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredCertificates = filteredCertificates ? filteredCertificates.slice(indexOfFirstItem, indexOfLastItem) : [];
  let countCertificates = indexOfFirstItem + 1;




    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderInstitutions />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Certificate
              </div>                        
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      
                      
                      <div className="flex flex-col justify-between my-2 pb-2">
                      {/* <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Upload Certificate</div> */}
                     
                      <div className="text-right">
  <button className="px-6 " 
  style={{ 
    width: '200px', 
    backgroundColor: '#095918', 
    borderRadius: '8px', 
    padding: '10px 20px', 
    color: 'white', 
    border: 'none', 
    }}
    onClick={hitUploadCertificateEndpoint}
    >
    {isNetworkLoading ? "Uploading" : "Upload Certificate"}
    </button>
  </div>
  <div style={{ display: 'flex', justifyContent: 'right' }}>
                              <p style={{ color: '#990000', fontSize: '12px' }}>{certificateUploadErrorMessage}</p>
                              </div>
                  </div>
                  
                  
                  
                  
                  
                  
                  
                  <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 ">
                  <div className="rounded-lg  flex-1 m-2 flex-col text-black" 
                  //style={{ backgroundColor: '#FFE5D3' }}
                  >
                  <div className='flex flex-col py-2'>
                              <label>Program Type</label>
                              <select
                              className="mt-2 p-2 focus rounded-lg"
                              onChange={(e) => handleDropdownChange(e.target.selectedIndex - 1)}
                              >
                                <option value="Select">Select</option>
                                {Array.isArray(programTypes) && programTypes.map((programType, index) => (
                                  <option key={index} value={programType.id}>
                                    {programType.name}
                                    </option>
                                    ))}
                              </select>
                          </div>
                      
                  </div>
                  <div className="rounded-lg  flex-1 m-2 flex-col text-black" 
                  //style={{ backgroundColor: '#E2FBD7' }}
                  >
                  <div className='flex flex-col py-2'>
                              <label>Program</label>
                              <select
                              className="mt-2 p-2 focus rounded-lg"
                              onChange={(e) => handleProgramDropdownChange(e.target.selectedIndex - 1)}
                              >
                                <option value="Select">Select</option>
                                {program.map((program, index) => (
                                  <option key={index} value={program.id}>
                                    {program.programName}
                                    </option>
                                    ))}
                              </select>
                          </div>
                      
                  </div>
                  <div className="rounded-lg  flex-1 m-2 flex-col text-black" 
                  //style={{ backgroundColor: '#DAD7FE' }}
                  >
                  <div className='flex flex-col py-2'>
                              <label>Excel File</label>
                              
                              <div className="flex flex-col py-2 px-4">
        <input
        type="file"
        id="paymentEvidenceInput"
        accept=".xlsx, .xls"
        onChange={handleExcelFileChanged}
        />
      </div>
                          </div>
                      
                  </div>
                  <div className="rounded-lg  flex-1 m-2 flex-col text-black" 
                  //style={{ backgroundColor: '#DAD7FE' }}
                  >
                  <div className='flex flex-col py-2'>
                              <label>Zip File</label>
                              
                              <div className="flex flex-col py-2 px-4">
        <input
        type="file"
        id="paymentEvidenceInput"
        accept=".zip"
        onChange={handleZipFileChanged}
        />
      </div>
                          </div>
                      
                  </div>


                  
              </div>



              <div className="flex flex-col items-center my-2 pb-2 sm:flex-row sm:justify-between">
                                                     
                                                     <div className="pt-2">
                                 <button className="px-6 " 
                                 style={{ 
                                   width: '200px', 
                                   backgroundColor: '#095918', 
                                   borderRadius: '8px', 
                                   padding: '10px 20px', 
                                   color: 'white', 
                                   border: 'none', 
                                   cursor: 'pointer'
                                   }}
                                   onClick={() => downloadExcelFile()}
                                   >
                                   Download Excel
                                   </button>
                                 </div>
                     
                     
                                 {/* <div className="pt-2">
                                 <button className="px-6 " 
                                 style={{ 
                                   width: '200px', 
                                   backgroundColor: '#095918', 
                                   borderRadius: '8px', 
                                   padding: '10px 20px', 
                                   color: 'red', 
                                   border: 'none', 
                                   }}
                                   onClick={() => genrateQrCodeAndCertificate()}
                                   >
                                   Qr Code
                                   </button>
                                 </div> */}
                                                 </div>

              






                  </div>
              </div>

              {loading ? (
    <div className='p-4'>Loading...</div>
  ) : (
              <div className="flex justify-between">
              <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                    <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Upload History</div>
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      
                      <div className="flex justify-between my-2 pb-2">
                      {/* <div className='text-xl pb-2'>Activities</div> */}
                     
                      <div className="flex items-center">
<input
type="text"
placeholder="Search by Date"
className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
onChange={handleSearchChange}
/>
<div className="flex items-center">
<FilterIcon className="text-gray-500 cursor-pointer" />
<span className="text-gray-500 ml-1">Filter Search</span>
</div>
</div>
                      
                  </div>
                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
          <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              S/N
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Date
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Batch
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Id
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Uploaded By
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Qr
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Certificates
            </th>
            
          </tr>
        </thead>
        
        <tbody>
          {currentFilteredCertificates.map((certificate) => (
              <tr key={certificate.id} onClick={(e) => handleRowClick(certificate, e)} style={{ cursor: "pointer" }}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {countCertificates++} {/* {user.id} */}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {certificate.date}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {certificate.batch}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {certificate.id}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {certificate.uploaded_by}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer'
                  }}  onClick={(e) => {
                    e.stopPropagation();
                    downloadQRCode(certificate.date);
                  }}>QRCode</span>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer'
                  }}
                   onClick={(e) => {
                    e.stopPropagation();
                    downloadCertificates(certificate.date);
                   }}>Certificates</span>           
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
                  </div>
              </div>
              )}

              {/* Pagination Controls */}
<div className="flex justify-center mt-4">
<button
onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
disabled={currentPage === 1}
className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Previous
</button>
<button
onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Next
</button>
</div>

              
              <SimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
    registrationError={registrationError}
    message='Certificate'
/>

          </div>
      </div>
  </div>
    );
}

export default InstitutionsCertificate;
