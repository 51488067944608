import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';


import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { usePaystackPayment } from 'react-paystack';

const customModalStyles = {
  content: {
    width: '80%', 
    height: '85%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '1%',
  },
};

const greenStyle = {
  backgroundColor: 'green',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const redStyle = {
  backgroundColor: 'red',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const SearchModal = ({ isOpen, onRequestClose, registrationStatus, registrationError, message, searchData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  //const [amount, setAmount] = useState(0.0);

  const sanitizedAmount = searchData && searchData.amount.replace(/,/g, '');
  const parsedAmount = parseFloat(sanitizedAmount);

  let user_email = localStorage.getItem('user_email');
  let user_id = localStorage.getItem('user_id');
  let token = localStorage.getItem('token');

  // PAYSTACK
  const config = {
    reference: (new Date()).getTime().toString(),
    email: user_email,
    amount: parsedAmount * 100, // lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_test_6df348391bf24359be0f66aa0d974f1cbd28d97e',
    metadata: {
      transaction_id: searchData.transaction_id,
      other_id: user_id,
    }
    
  };
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call
    //setInputValue('');
    //alert(reference);
    //alert("Payment " + JSON.stringify(reference.status, null, 2));
    // console.log(reference);

    // setIsModalOpen(false);
    onRequestClose();
    navigate(`/guest-dashboard`);
  };
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed
    //alert('closed');
    // console.log('closed');
  }
  const initializePayment = usePaystackPayment(config);
  // PAYSTACK

  const OpenPaymentPage = async () => {
    // Assuming parsedRequest.amount is a string like "100,000.00"
    
  
    if (!isNaN(parsedAmount)) {
      //setAmount(parsedAmount);
  
      // Use the parsed float value directly in the alert
      //alert('Parsed amount:', parsedAmount);
    //   alert(
    //     (new Date()).getTime().toString() + "\n" +
    //  user_email + "\n" +
    //  parsedAmount * 100 + "\n" + //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    // 'pk_test_6df348391bf24359be0f66aa0d974f1cbd28d97e' + "\n" +
    // searchData.transaction_id + "\n" +
    // user_id
    //   );
  
    if (searchData.status == "pending") {
      initializePayment(onSuccess, onClose);
    } else {
      alert("status is already: " + searchData.status);
    }
      
    } else {
      // Handling the case when parsing fails
      alert('Failed to parse amount');
    }
  };
  


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel=""
      style={customModalStyles}
    >
      <div className="flex flex-col justify-between w-full text-center pb-6 items-center">
        <div className="pt-8 pb-8">
          {registrationStatus === 'Search Successful' ? (
            <FontAwesomeIcon
              icon={faCheck}
              color="white"
              className="ml-4 mr-4"
              style={{ ...greenStyle }}
              
            />
          ) : (
            <FontAwesomeIcon
              icon={faTimes}
              color="white"
              className="ml-4 mr-4"
              style={{ ...redStyle }}
            />
          )}
        </div>
        <h2
          style={{ fontSize: '2rem', marginTop: '0' }}
          className={`text-2xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl ${
            registrationStatus === 'Search Successful'
              ? 'text-green-500'
              : 'text-red-500'
          } py-2 px-4`}
        >
          {registrationStatus === 'Search Successful'
            ? "Certificate verification to be confirmed after succesful payment"//message + ' Successful'
            : message + ' Failed'}
        </h2>
        <div className="pt-4">
          {registrationStatus === 'Search Successful'
            ? ''
            : ''}
        </div>
      </div>

      <div>
        {/* <div>Id: {searchData.id}</div> */}
        <div>Status: {searchData.status}</div>
        <div>Certificate Number: {searchData.certificate_number}</div>
        <div>Transaction Id: {searchData.transaction_id}</div>
        <div>Amount: {searchData && searchData.amount}</div>
        {/* <div>Created Date: {searchData.created_date}</div> */}

      </div>

      <div className='text-center ' style={{ display: 'flex', justifyContent: 'center' }}>
        <p 
        style={{ 
          //color: 'red',//'#990000', 
          fontSize: '16px' }}
          className={`text-center mb-4 ${
            registrationStatus === 'Search Successful'
              ? 'text-green-500'
              : 'text-red-500'
          }`}
        >
          {registrationError}</p>
      </div> 

      <div className='flex flex-row pt-6 '>
      <button className="rounded-button-blue shadow-lg mr-1" onClick={onRequestClose}>
        Cancel
      </button>
      <button className="rounded-button-blue shadow-lg ml-1" onClick={OpenPaymentPage}>
        Make Payment
      </button>
      </div>
    </Modal>
  );
};

export default SearchModal;
