import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import GuestSideNav from './GuestSideNav';
import HeaderGuest from './HeaderGuest';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import LoadingScreen from '../../LoadingScreen';
import SearchModal from './modal/SearchModal';

const GuestDashboard = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [noPending, setPending] = useState('-');
  const [noApproved, setApproved] = useState('-');


  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [registrationError, setRegistrationErrorMessage] = useState('');
  const [isNetworkLoading, setIsNetworkLoading] = useState(false);


  const [searchData, setSearchData] = useState('');




  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);


  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
    //clearFields();
    if (registrationStatus == "Upload Certificate Successful") {
      //navigate("/");
      //clearFields();

    }
    
  };


  const handleData = async () => {
    //alert(token);
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/guest/dashboard', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setPending(response.data.data.pending);
        setApproved(response.data.data.approved);
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };



  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    
    //alert("Search for: " + e.target.value);
  };
  // const filteredUsers = users.filter((user) =>
  // user.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
  // user.institution.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const hitEndpointToSearch = async () => {
    if (searchQuery != '') {
      //alert("Search for: " + searchQuery);
      //alert("token: " + token);

      setIsNetworkLoading(true);

      try {
  
        //const formData = new FormData();
        //formData.append('certificate_number', searchQuery);  
  
        // const response = await axios.get("https://gacsstage.zaptrance.ng/api/guest/guest/certificates/search", formData, {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${token}`,
        //   },
        // });

        const response = await axios.get(`https://gacsstage.zaptrance.ng/api/guest/guest/certificates/search?certificate_number=${searchQuery}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },});



  
        setIsNetworkLoading(false);
        //alert("guest search: " + JSON.stringify(response.data.data, null, 2));
  
        if (response.data.success) {
          // search was successful, you can handle the response here
          //alert("ok");
          setRegistrationStatus("Search Successful");
          
          //alert('Search successful:' + response.data.message);

          //alert("Search: " + JSON.stringify(response.data.data, null, 2));
          setSearchData(response.data.data);

          setIsSearchModalOpen(true);
  
        } else {
          // Handle the case where search was not successful
          alert("not ok");
          setRegistrationStatus("Search Failed");
          setRegistrationErrorMessage(response.data);

          
          setIsSearchModalOpen(true);
        }
      } catch (error) {
        setIsNetworkLoading(false);
  
        setRegistrationStatus("Search Failed");
        alert('Search Failed2:'+ error.message);
    
    
        if (error.response && error.response.data && error.response.data.data) {
          const dataKeys = Object.keys(error.response.data.data);
      
          if (dataKeys.length > 0) {
        // Construct an error message from the data keys
        const errorMessage = dataKeys
          .map(key => {
            const messages = error.response.data.data[key];
            //return `${key}: ${messages.join(', ')}`;
            return `${messages.join(', ')}`;
          })
          .join('\n');
  
        setRegistrationErrorMessage(errorMessage);
      } else {
        // If there are no data keys, set a generic error message
        setRegistrationErrorMessage('An error occurred during search.');
      }
    } else {
      // Handle other error cases if needed
      setRegistrationErrorMessage('An error occurred during search.');
    }
  }


    } else {
      alert("Enter a valid search query");
    }
    
  }





    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderGuest />
          <div className="p-4 flex-1 flex-col text-black" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Dashboard
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#FFE5D3' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noPending}
                      </h1>
                      <div>Pending</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#E2FBD7' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noApproved}
                      </h1>
                      <div>Approved</div>
                  </div>
                  
              </div>

              {/* <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Recent Activities</div>
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      <div className='text-xl pb-2'>Activities</div>
                      <div className='overflow-x-auto'>
                      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Activities
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Date
            </th>
            
          </tr>
        </thead>
        
      </table>
    </div>
                  </div>
              </div> */} 

              <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{backgroundColor: '#ffffff'}}>
                <div className="flex px-4 my-2 pb-2 ">
                <div className="flex pl-2  items-center">
                    <p>
                      Search for Request: 
                    </p>
                  </div>

                  <div className="flex pl-2  items-center">
                    <input
                    type="text"
                    placeholder="Enter Search Query"
                    className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
                    onChange={handleSearchChange}
                    />
                  </div>

                  <button className="px-4 py-3 m-4" 
                    style={{ width: '200px', 
                    backgroundColor: '#095918', borderRadius: '8px', 
                    padding: '10px 20px', color: 'white', border: 'none', }}
                    onClick={hitEndpointToSearch}
                    >
                      {isNetworkLoading ? <LoadingScreen /> : 'Search'}
                    </button>
                </div>
              </div>

              




              <SearchModal 
isOpen={isSearchModalOpen}
onRequestClose={closeSearchModal}
registrationStatus={registrationStatus}
    registrationError={registrationError}
    message='Search'
    searchData={searchData}
/>

          </div>
      </div>
  </div>
    );
}

export default GuestDashboard;
