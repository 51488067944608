import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import HeaderRectorAndRegistrar from './HeaderRector';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

import FilterIcon from '@mui/icons-material/FilterListOutlined';

const RectorAndRegistrarReport = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [reports, setReports] = useState([]);

  const [loading, setLoading] = useState(true);

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {

    setLoading(true);
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution/report', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setReports(response.data.data);
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      setLoading(false);
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


  const downloadQRCode = async (qrLink) => {
    //e.stopPropagation();
    //alert("DownloadSingleQRCode");
    // alert("https://gacsstage.zaptrance.ng/" + qrLink);
    try {
      // Replace the file URL with the actual URL of your Excel file
      const qrFileUrl = "https://gacsstage.zaptrance.ng" + qrLink;
  
      // Open the file URL in a new window or tab to initiate the download
      window.open(qrFileUrl, '_blank');
    } catch (error) {
      alert('Error downloading QR file:'+ error);
    }
    //batchCertificate.qrLink
  }

  
  const downloadCertificates = async (certLink) => {
    //e.stopPropagation();
    //alert("DownloadSingleCertificates");
    // alert("https://gacsstage.zaptrance.ng/" + certLink);
    try {
      // Replace the file URL with the actual URL of your Excel file
      const certFileUrl = "https://gacsstage.zaptrance.ng" + certLink;
  
      // Open the file URL in a new window or tab to initiate the download
      window.open(certFileUrl, '_blank');
    } catch (error) {
      alert('Error downloading Cert file:'+ error);
    }
    //batchCertificate.certLink
  }

  // const downloadQRCode = async (qrLink) => {
  //   //e.stopPropagation();
  //   //alert("DownloadQRCode"); /institution-admin/certificates/download/1/qr/2023-12-13
  //   // try {
  //   //   // Replace the file URL with the actual URL of your Excel file
  //   //   const qrFileUrl = "https://gacsstage.zaptrance.ng/institution-admin/certificates/download/1/qr/" + qrLink;
  //   //   alert(qrFileUrl);
      
  //   //   // Open the file URL in a new window or tab to initiate the download
  //   //   window.open(qrFileUrl, '_blank');
  //   // } catch (error) {
  //   //   alert('Error downloading QR file:'+ error);
  //   // }

  //   try {
  //     // const qrFileUrl = `https://gacsstage.zaptrance.ng/institution-admin/certificates/download/1/qr${qrLink}`;
  //     // const qrFileUrl = `https://gacsstage.zaptrance.ng/api/institution-admin/certificates/download/1/qr${qrLink}`;
  //     const qrFileUrl = "https://gacsstage.zaptrance.ng" + qrLink;
  //     alert(qrFileUrl);
      
  //     const response = await axios.get(qrFileUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: 'blob', // Set the response type to blob
  //     });
  
  //     // Create a temporary link and trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(new Blob([response.data]));
  //     //link.download = 'qr_code.zip'; // Set the desired filename and extension
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     alert('Error downloading QR file:' + error);
  //   }
  // }

  
  // const downloadCertificates = async (certLink) => {
  //   //e.stopPropagation();
  //   //alert("DownloadCertificates"); /institution-admin/certificates/download/1/certificate/2023-12-13
  //   // try {
  //   //   // Replace the file URL with the actual URL of your Excel file
  //   //   const certFileUrl = "https://gacsstage.zaptrance.ng/institution-admin/certificates/download/1/certificate/" + certLink;
  //   //   alert(certFileUrl);
      
  //   //   // Open the file URL in a new window or tab to initiate the download
  //   //   window.open(certFileUrl, '_blank');
  //   // } catch (error) {
  //   //   alert('Error downloading Cert file:'+ error);
  //   // }

  //   try {
  //     // const certFileUrl = `https://gacsstage.zaptrance.ng/institution-admin/certificates/download/1/certificate${certLink}`;
  //     // const certFileUrl = `https://gacsstage.zaptrance.ng/api/institution-admin/certificates/download/1/certificate/${certLink}`;
  //     const certFileUrl = "https://gacsstage.zaptrance.ng" + certLink;
  //     alert(certFileUrl);

  //     const response = await axios.get(certFileUrl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: 'blob', // Set the response type to blob
  //     });
  
  //     // Create a temporary link and trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(new Blob([response.data]));
  //     link.download = 'cert_file.pdf'; // Set the desired filename and extension
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     alert('Error downloading Certificate File:' + error);
  //   }
  // }
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(reports ? reports.length : 0);
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredReports = reports.filter((report) =>
  report.certificate_number.includes(searchQuery.toLowerCase())
  || report.name.includes(searchQuery.toLowerCase())
  || report.registration_number.includes(searchQuery.toLowerCase())
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredReports = filteredReports ? filteredReports.slice(indexOfFirstItem, indexOfLastItem) : [];
  let countReports = indexOfFirstItem + 1;



    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRectorAndRegistrar />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Rector Report
              </div>

              {loading ? (
    <div className='p-4'>Loading...</div>
  ) : (
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      {/* <div className='text-xl pb-2'>Activities</div> */}

                      <div className="flex justify-between my-2 pb-2">
                      {/* <div className='text-xl pb-2'>Activities</div> */}
                     
                      <div className="flex items-center">
<input
type="text"
placeholder="Search by Date"
className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
onChange={handleSearchChange}
/>
<div className="flex items-center">
<FilterIcon className="text-gray-500 cursor-pointer" />
<span className="text-gray-500 ml-1">Filter Search</span>
</div>
</div>
                      
                  </div>

                      <div className='overflow-x-auto'>
      <table className='min-w-full bg-white shadow-md overflow-hidden'>
        {/* Table Header */}
        <thead>
          <tr>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              S/N
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Certificate Number
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Name
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Registration Number
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Year
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Grade
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Program Type
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Qr
            </th>
            <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
              Download Certificates
            </th>
            
          </tr>
        </thead>
        <tbody>
          {currentFilteredReports.map((report) => (
            <tr key={report.id}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {countReports++} {/* {report.id} */}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.certificate_number}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.name}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.registration_number}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.program}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.year}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.grade}
              </td>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
              {report.program_type}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer'
                  }}  onClick={(e) => {
                    e.stopPropagation();
                    downloadQRCode(report.qrLink);
                  }}>QRCode</span>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="text-center px-2 py-1" 
                style={{ 
                  width: '200px', 
                  backgroundColor: '#095918', 
                  borderRadius: '8px', 
                  padding: '10px 20px', 
                  color: 'white', 
                  border: 'none', 
                  cursor: 'pointer'
                  }}
                   onClick={(e) => {
                    e.stopPropagation();
                    downloadCertificates(report.certLink);
                   }}>Certificates</span>           
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </div>
              </div>
              )}

              {/* Pagination Controls */}
<div className="flex justify-center mt-4">
<button
onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
disabled={currentPage === 1}
className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Previous
</button>
<button
onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)))}
disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
style={{width: '100px', borderRadius: '30px',}}
>
Next
</button>
</div>

          </div>
      </div>
  </div>
    );
}

export default RectorAndRegistrarReport;
