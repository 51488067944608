import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import LoadingScreen from '../../../LoadingScreen';

import SimpleModal from './SimpleModal';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';



const customModalStyles = {
  content: {
    width: '80%', 
    //height: '90%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '1%',
  },
};

const AddProgramToInstitutionModal = ({ isOpen, onRequestClose, institution }) => {
  const [programId, setProgramId] = useState('');
  const [programIdTwo, setProgramIdTwo] = useState('');
  const [template, setTemplate] = useState('');


  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);

  const [registrationError, setRegistrationErrorMessage] = useState('');

  const [isNetworkLoading, setIsNetworkLoading] = useState(false);


  const [programTypes, setProgramTypes] = useState([]);
  const [programTypesTwo, setProgramTypesTwo] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      getProgramTypesOne();
    }
  }, [navigate]);



  // Function to close the modal
  const closeSimpleModal = () => {
    setIsSimpleModalOpen(false);
    clearFields();
    if (registrationStatus == "Registration Successful") {
      //navigate("/");
      onRequestClose();
    }
    
  };


  const clearFields = () => {
    //
    setProgramId('');
    setTemplate('');
    setProgramIdTwo('');

    //setProgramTypes(''); //<- commented 27-12-23 to prevent reset
    setProgramTypesTwo('');
  
    setRegistrationStatus(null);
    //setIsSimpleModalOpen(false);
  
    //setRegistrationErrorMessage('');
          //
  }


  const hitAddProgramToInsitutionEndpoint = async () => {
    //onAddInstitution(institutionName);
    //setInstitutionName('');

    setRegistrationErrorMessage('');
    setIsNetworkLoading(true);

    // alert(
    //   'programId: ' + programId + "\n\n" +
    //   'programId II: ' + programIdTwo + "\n\n" +
    //   'institutionId: ' + institution.id + "\n" +
    //   'template: ' + template + "\n" + "\n" +
    //   "https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/programs"
    // );

    if (
      programId == '' ||
      programIdTwo == '' ||
      template == ''
      ) {
      alert("Please select a program type and/or template.");
      setIsNetworkLoading(false);
      return;
    }


    try {
      const token = localStorage.getItem('token')
      //alert(token);

      const formData = new FormData();
      //formData.append('program', programId);
      formData.append('program', programIdTwo);
      formData.append('template', template);

      // Append the actual file to the formData

      const response = await axios.post("https://gacsstage.zaptrance.ng/api/admin/institutions/" + institution.id + "/programs", formData, {
        headers: {
          //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setIsNetworkLoading(false);

      if (response.data.success) {
        // Registration was successful, you can handle the response here
        //alert("ok");
        setRegistrationStatus("Registration Successful");
        setIsSimpleModalOpen(true);
        // console.log('Registration successful:' + response.data.message);


        //clearFields();

        // // Close the modal
        // alert("Registration Successful");
        // onRequestClose();
        // //onAddProgram();

        // You can also redirect the user to a confirmation page or do any other actions.
      } else {
        // Handle the case where registration was not successful
        //alert("not ok");
        setRegistrationStatus("Registration Failed");
        setIsSimpleModalOpen(true);
        alert('Registration failed #:' + response.data);
        setRegistrationErrorMessage(response.data);
      }
    } catch (error) {
      setIsNetworkLoading(false);

      setRegistrationStatus("Registration Failed");
      //alert('Registration Failed:'+ error.response.data.message);
      //alert('Registration Failed:'+ error.response.data.data);

      if (error.response && error.response.data && error.response.data.message) {
        // Print the specific error message received from the server
        //console.error('Registration Failed:'+ error.response.data.message);
    
        // You can also set the error message to a state variable if needed
        setRegistrationErrorMessage(error.response.data.data);
      } else {
        // Handle other error cases if needed
        //console.error('An error occurred during registration.');

        setRegistrationErrorMessage('An error occurred during registration.');
      }

      setIsSimpleModalOpen(true);
    }

  };



  const getProgramTypesOne = async () => {
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin/program_types', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      
      if (response.data.success) {
        
        setProgramTypes(response.data.data);
        setLoading(false); 

        //alert("Modal programTypes: " + JSON.stringify(response.data.data, null, 2));
      } else {
        
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      alert('Request error:'+ error);
    }
  };


  const getProgramTypesTwo = async (id) => { 
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin/program_types/' + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setProgramTypesTwo(response.data.data);
        setLoading(false); 

        //alert("Modal programTypesTwo: " + JSON.stringify(response.data.data, null, 2));
      } else {
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      alert('Request error:'+ error);
    }
  };


  
  const handleDropdownChange = (event, i) => {
    const selectedValue = event.target.value;
    //alert(selectedValue);


    if (i != 0) {
      
      setProgramId(selectedValue);
      getProgramTypesTwo(selectedValue);
    } else {

      setProgramId("");
      setProgramTypesTwo([]);
      //getProgramTypesTwo(selectedValue);
    }

    
  };

  const handleDropdownChangeTwo = (event) => {
    const selectedValue = event.target.value;
    setProgramIdTwo(selectedValue);
    
  };




  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Institution Modal"
      style={customModalStyles}
    >
      








<div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>Add Program To {institution.name} Institution</h2>
  </div>
  <div>
    <button
      //onClick={onRequestClose}
      onClick={() => {
        onRequestClose();
        clearFields();
        setRegistrationErrorMessage('');
        setIsSimpleModalOpen(false);
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>
</div>

<div className="w-full px-8 pt-4">

{/* <div className="flex flex-col py-2">
  <label>Institution Id: <strong>{institution.id}</strong></label>
</div>

<div className="flex flex-col py-2">
    <label>Institution Name: <strong>{institution.name}</strong></label>
  </div>
  <br /> */}


<div className="flex flex-col py-2">
  <label>Program Type:</label>
  <select
  className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
  onChange={(e) => handleDropdownChange(e, e.target.selectedIndex)}
>
  <option value="Select">Select</option>
  {Array.isArray(programTypes) &&
    programTypes.map((type, index) => (
      <option key={index} value={type.id}>
        {type.name}
      </option>
    ))}
</select>
</div>

<div className="flex flex-col py-2">
    <label>Program Id</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      placeholder="Please enter prgram Id"
      value={programId}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>




<div className="flex flex-col py-2">
  <label>Program Type II:</label>
  <select
  className="mt-2 p-2 focus border-nbteGreen border-2 rounded-xl"
  onChange={(e) => handleDropdownChangeTwo(e)}
>
  <option value="Select">Select</option>
  {Array.isArray(programTypesTwo) &&
    programTypesTwo.map((type, index) => (
      <option key={index} value={type.id}>
        {type.name}
      </option>
    ))}
</select>
</div>

<div className="flex flex-col py-2">
    <label>Program Id II</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      placeholder="Please enter prgram Id"
      value={programIdTwo}
      readOnly={true}
      //onChange={(e) => setProgramIdTwo(e.target.value)}
    />
  </div>


  <div className="flex flex-col py-2" style={{ overflowY: 'auto' }}>
    <label>Template</label>
    {/* <textarea
      style={{ backgroundColor: '#EDF2F6', width: '100%', height: '200px' }} borderRadius: '8px', padding: '8px'
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      placeholder="Please enter prgram template"
      value={template}
      //readOnly={true}
      onChange={(e) => setTemplate(e.target.value)} 
    /> */}
   <textarea
  style={{
    width: '100%',
    height: '200px',
    backgroundColor: '#EDF2F6',
    borderRadius: '8px',
    padding: '8px',
    boxSizing: 'border-box', // Ensures padding and border are included in the total height
    overflowY: 'auto', // Add this line to make it scrollable along the y-axis
  }}
  className="mt-2"
  placeholder="Please enter program template"
  value={template}
  onChange={(e) => setTemplate(e.target.value)}
></textarea>


  </div>

  


  <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
  <p className='text-center mb-4 text-red-500'
  style={{ 
    //color: 'red',//'#990000', 
    fontSize: '12px' }}>{registrationError}</p>
</div>





  <div className="text-center pt-4">
      <button className="px-6 py-3 m-4" 
      style={{
      width: '200px',
      backgroundColor: '#095918',
      borderRadius: '30px',
      padding: '10px 20px',
      color: 'white',
      border: 'none',
    }}
      onClick={hitAddProgramToInsitutionEndpoint}
      >
        {isNetworkLoading ? (
                <LoadingScreen />
              ) : (
                'Add'
              )}
        </button>
      </div>


</div>

<SimpleModal 
isOpen={isSimpleModalOpen}
onRequestClose={closeSimpleModal}
registrationStatus={registrationStatus}
              registrationError={registrationError}
              message='Program'
/>


    </Modal>
  );
};

export default AddProgramToInstitutionModal;
