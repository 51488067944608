import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderZaptrance from './HeaderZaptrance';
import ZaptranceSideNav from './ZaptranceSideNav';
import axios from 'axios';

import AddPaymentModal from './modals/AddPaymentModal';

import LayersIcon from '@mui/icons-material/Layers';
import FilterIcon from '@mui/icons-material/FilterListOutlined';

import AddInstitutionModal from './modals/AddInstitutionModal';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/ModeOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';

import { format, parseISO } from 'date-fns';

const itemsPerPage = 10;

const ZaptrancePayment = () => {
 const navigate = useNavigate();

  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [institutions, setInstitutions] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);


  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleAddPayment = (paymentName) => {
    closeAddModal();

    //retrive
    handleData();
  };


  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      handleData();
    }
  }, [navigate]);
  

  const handleRowClick = (payment, e) => {
    
    //alert(JSON.stringify(payment, null, 2));
    //alert(registration.id);

    setSelectedRow(payment);
    setSelectedPayment(payment);
    
    //alert(institution.name);

    //alert(programTypes);
    //alert(JSON.stringify(programTypes, null, 2));
    //return;
    
    // setIsAddProgramModalOpen(true);
    // navigate(`/zaptrance-institution-detail/${institution}`);

    const paymentString = JSON.stringify(payment);
    navigate(`/zaptrance-payment-detail/${encodeURIComponent(paymentString)}`);

  };



  const handleData = async () => { 
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin/payments', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setPayments(response.data.data);
        setLoading(false); 

        await getInstitutions();
      } else {
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      console.error('Request error:'+ error);
    }
  };

  const getInstitutions = async () => { 
    try {
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/admin/institutions', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setInstitutions(response.data.data);
        setLoading(false); 
      } else {
        console.error('Request failed:' + response.data.message);
      }
    } catch (error) {
      console.error('Request error:'+ error);
    }
  };


  
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const filteredPayments = payments.filter((payment) =>
  payment.institution.toLowerCase().includes(searchQuery.toLowerCase())
);



  const totalFilteredItems = filteredPayments.length;
const totalFilteredPages = Math.ceil(totalFilteredItems / itemsPerPage);
const indexOfLastFilteredItem = currentPage * itemsPerPage;
const indexOfFirstFilteredItem = indexOfLastFilteredItem - itemsPerPage;
const currentFilteredPayments = filteredPayments.slice(
  indexOfFirstFilteredItem,
  indexOfLastFilteredItem
);

let countFiltered = indexOfFirstFilteredItem + 1;



  



  return (
    <div className="content flex-grow">
        <div className="inner-content">
          <HeaderZaptrance />
          <div className=' p-4' style={{ backgroundColor: '#F6F7FB' }}>
          <div className='text-black p-4 flex text-xl'>
                            <div><LayersIcon className='mr-4'/></div>Payments
                        </div>

                        <div className="text-right">
            <button className="px-6 py-3 m-4" 
            style={{ width: '200px', 
            backgroundColor: '#095918', borderRadius: '8px', 
            padding: '10px 20px', color: 'white', border: 'none', }}
            onClick={openAddModal}
            >
              Make New Payment
              </button>
            </div>


            {loading ? (
              <div className='p-4'>Loading...</div>
            ) : (
              <div>

<div className="flex justify-between my-2 pb-2 ">
  <div className="flex items-center">
    <div className="text-xl">Payments</div>
  </div>
  <div className="flex items-center">
    <input
      type="text"
      placeholder="Search"
      className="border border-gray-300 rounded-lg py-1 px-2 mr-4"
      onChange={handleSearchChange}
    />
    <div className="flex items-center">
      <FilterIcon className="text-gray-500 cursor-pointer" />
      <span className="text-gray-500 ml-1">Filter Search</span>
    </div>
  </div>
</div>

              <div className='overflow-x-auto'>
                <table className='min-w-full bg-white shadow-md rounded-lg overflow-hidden'>
                  {/* Table Header */}
                  <thead>
                    <tr>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        ID
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Institution
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Committed By
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Date
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Date Paid
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Teller Number
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider'>
                        Expiry Date
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Action
                      </th>
                      <th style={{backgroundColor: '#095918'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Status
                      </th>
                      
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    {currentFilteredPayments.map((payment) => (
                      <tr key={payment.id} onClick={(e) => handleRowClick(payment, e)} style={{ cursor: "pointer" }}>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                        {countFiltered++} {/* {payment.id} */}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {payment.institution}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {payment.committed_by}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {/* {payment.date} */}
                          {payment.date.toString() != "0000-00-00" ? format(parseISO(payment.date), "yyyy-MM-dd HH:mm") : '0000-00-00'}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {/* {payment.date_paid} */}
                          {payment.date_paid.toString() != "0000-00-00" ?  format(parseISO(payment.date_paid), "yyyy-MM-dd HH:mm") : '0000-00-00'}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {payment.teller_number}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          {payment.expiry_date || 'N/A'}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                        <span
    className="text-green-500 cursor-pointer mr-2 "
    onClick={() => {
      //setShowDialog(false); handleApproveClick(registration); 
    }}
  >
    More
  </span>
  {/* Deny button */}
  {/* <span
    className="text-red-500 cursor-pointer "
    onClick={() => {
      //setShowDialog(false); handleDeclineClick(registration); 
    }}
  >
    Decline
  </span> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>
            )}

{/* Pagination Controls */}
<div className="flex justify-center mt-4">
  <button
    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
    disabled={currentPage === 1}
    className="px-4 py-2 mr-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
    style={{ width: '100px', borderRadius: '30px' }}
  >
    Previous
  </button>
  <button
    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalFilteredPages))}
    disabled={currentPage === totalFilteredPages}
    className="px-4 py-2 bg-gray-300 hover:bg-nbteGreen hover:text-white transition-colors duration-300"
    style={{ width: '100px', borderRadius: '30px' }}
  >
    Next
  </button>
</div>


<AddPaymentModal
              isOpen={isAddModalOpen}
              onRequestClose={closeAddModal}
              onAddPayment={handleAddPayment}
              institutions={institutions}
            />
          </div>
        </div>
      </div>
  );
};

export default ZaptrancePayment;
