import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GuestSideNav from './GuestSideNav';
import HeaderGuest from './HeaderGuest';

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

import LoadingScreen from '../../LoadingScreen';
import PaymentModal from './modal//PaymentModal';

import { usePaystackPayment } from 'react-paystack';


const GuestRequestListsDetail = () => {
  const navigate = useNavigate();
  
  // Define state variables to store data
  const [loading, setLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  
  const [payData, setPayData] = useState([]);

    
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);


  const [currentRequest, setCurrentRequest] = useState([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  //const [amount, setAmount] = useState(0.0);


  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState(null);

 
 
  //let params = useParams();
  const { request } = useParams();
  const parsedRequest = JSON.parse(decodeURIComponent(request));
  //alert("parsedInstitution: " + parsedInstitution.id);

  const sanitizedAmount = parsedRequest.amount.replace(/,/g, '');
  const parsedAmount = parseFloat(sanitizedAmount);


  const [htmlContent, setHtmlContent] = useState('');
 
  const clearFields = () => {

  }


  let user_email = localStorage.getItem('user_email');
  let user_id = localStorage.getItem('user_id');
  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      //handleData();
    }
  }, [navigate]); 


  // PAYSTACK
  const config = {
    reference: (new Date()).getTime().toString(),
    email: user_email,
    amount: parsedAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_test_6df348391bf24359be0f66aa0d974f1cbd28d97e',
    metadata: {
      transaction_id: parsedRequest && parsedRequest.transaction_id,
      other_id: user_id,
    }
  };
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    //setInputValue('');
    //alert(reference);
    // alert(JSON.stringify(reference, null, 2));
    //alert("Payment " + JSON.stringify(reference.status, null, 2));
    // console.log(reference);
    navigate(`/guest-request-lists`);
  };
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //alert('closed');
    // console.log('closed');
  }
  const initializePayment = usePaystackPayment(config);
  // PAYSTACK


  const OpenPaymentPage = async () => {
    // Assuming parsedRequest.amount is a string like "100,000.00"
    
  
     if (!isNaN(parsedAmount)) {
    //   //setAmount(parsedAmount);
  
    //   // Use the parsed float value directly in the alert
    //   //alert('Parsed amount:', parsedAmount);
    //   alert(
    //     (new Date()).getTime().toString() + "\n" +
    //  user_email + "\n" +
    //  parsedAmount * 100 + "\n" + //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    // 'pk_test_6df348391bf24359be0f66aa0d974f1cbd28d97e' + "\n" +
    // parsedRequest.transaction_id + "\n" +
    // user_id
    //   );

    if (parsedRequest.status == "pending") {
      initializePayment(onSuccess, onClose);
    } else {
      alert("status is already: " + parsedRequest.status);
    }
  
    } else {
      // Handling the case when parsing fails
      alert('Failed to parse amount');
    }
  };
  
  
  


  

  return (
    <div className="content flex-grow">
        <div className="inner-content">
          <HeaderGuest />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
            
            <div>

                <div className="flex justify-between items-center w-full px-2 pt-4">
  <div className="flex items-center">
    <FontAwesomeIcon icon={faBuilding} color="black" className='mr-6' size="2x"/>
    <h2 className='text-xl'>Request Details: {parsedRequest.id}</h2>
  </div>


  <div>
    <button
      onClick={()=> {
        navigate("/guest-request-lists");
      }}
      className="border p-2"
      style={{
        background: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        borderColor: 'black',
        display: 'inline-block',
        width: '30px',
        height: '30px',
        padding: '0',
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon icon={faTimes} color="black" />
      </span>
    </button>
  </div>


</div>


<div className="w-full px-8 pt-4">

      

<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">

<div className="flex flex-col py-2">
    <label>Certificate Number</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={parsedRequest.certificate_number}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>

  <div className="flex flex-col py-2">
    <label>Transaction ID</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={parsedRequest.transaction_id}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
  <label>Status:</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={parsedRequest.status}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div>

<div className="flex flex-col py-2">
    <label>Amount</label>
    <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram name"
      value={parsedRequest.amount}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
  </div>

  
</div>


<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
<div className="flex flex-col py-2">
  <label>Created Date</label>
  <input
      style={{ backgroundColor: '#EDF2F6', width: '100%' }}
      className="rounded-lg mt-2 p-2 focus"
      type="text"
      //placeholder="Please enter prgram Id"
      value={parsedRequest.created_date}
      readOnly={true}
      //onChange={(e) => setProgramId(e.target.value)}
    />
</div>


</div>
 

  


<div className="text-center pt-2">
      

      {
      // additionalVariable == 'noEdit' ? <div></div> : 
      <button className="px-6 py-3 m-4" 
    style={{
    width: '200px',
    backgroundColor: '#095918',
    borderRadius: '30px',
    padding: '10px 20px',
    color: 'white',
    border: 'none',
  }}
  onClick={() => {
    if (!isPaymentLoading) {
      OpenPaymentPage();
    }
  }}
    >
      
            {isPaymentLoading ? <LoadingScreen /> : 'Make Payment'}
      </button>}

      <button className="px-6 py-3 m-4" 
    style={{
    width: '200px',
    backgroundColor: '#095918',
    borderRadius: '30px',
    padding: '10px 20px',
    color: 'white',
    border: 'none',
  }}
  onClick={() => {
    //onRequestClose();
    clearFields();
    setRegistrationErrorMessage('');
    //alert(institution.id);
    navigate("/guest-request-lists");
  }}
    >
      
            Okay
      </button>
    </div>


</div>
              

              </div>
            



              <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        paymentUrl={paymentUrl}
      />
           

          </div>
        </div>
      </div>
  );
};

export default GuestRequestListsDetail;
