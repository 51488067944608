import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import ZaptranceSideNav from './RectorSideNav';
import HeaderRectorAndRegistrar from './HeaderRector';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';

const RectorAndRegistrarDashboard = () => {
  
  const navigate = useNavigate();

  // Define state variables to store data
  const [noOfCert, setNumberOfCert] = useState([]);
  const [noOfUsers, setNoOfUsers] = useState([]);
  const [noOfPrograms, setNoOfPrograms] = useState([]);

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, [navigate]);



  const handleData = async () => {
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://gacsstage.zaptrance.ng/api/institution', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setNumberOfCert(response.data.data.numberOfCert);
        setNoOfUsers(response.data.data.users);
        setNoOfPrograms(response.data.data.programs);
      } else {
        // Handle the case where the request was not successful
        alert('Request failed:' + response.data.message);
      }
    } catch (error) {
      //alert(error);
      alert('Request error:'+ error);
      // Handle request error, e.g., display an error message to the user
    }
  };


    return (
      <div className="content flex-grow">
      <div className="inner-content">
          <HeaderRectorAndRegistrar />
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            
              <div className='text-black p-4 flex  text-xl'>
                  <div><LayersIcon className='mr-4'/></div>Dashboard
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#FFE5D3' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfCert}
                      </h1>
                      <div>Certificates generated</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#E2FBD7' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfUsers}
                      </h1>
                      <div>Users</div>
                  </div>
                  <div className="rounded-lg bg-gray-300 p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#DAD7FE' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                          {noOfPrograms}
                      </h1>
                      <div>Programs</div>
                  </div>
              </div>
              <div className="text-black p-4 flex"><div><HistoryIcon className='mr-4'/></div>Recent Activities</div>
              <div className="flex justify-between">
                  <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{ backgroundColor: '#ffffff' }}>
                      
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
}

export default RectorAndRegistrarDashboard;
