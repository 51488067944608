import React from 'react';

const LoadingScreen = () => {

  return (
    <div >
      <h2>Please wait...</h2>
    </div>
  )
}

export default LoadingScreen;
