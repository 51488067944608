import React, { useState } from 'react';
import { useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import HeaderWithHome from "../../HeaderWithHome";
import FooterTwo from "../../FooterTwo";
import axios from 'axios';

import backRadar from '../../../assets/backRadar.png'

import LoadingScreen from '../../LoadingScreen';

export default function LoginPage(
    { onLogin }
) {


    useEffect(() => {

        localStorage.setItem('token', "");
        localStorage.setItem('user_type', "");
        // onLogout();
    
        // navigate('/');
      }, []);
      
      
      const [email, setEmail] = useState("Enter your email");
      const [password, setPassword] = useState('Enter your password');


    const [isSigninLoading, setIsSigninLoading] = useState(false);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    

    const pageStyle = {
        height: '100vh', 
        margin: '0',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#095918',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center', 
        marginTop: '20px', 
    };

    const buttonStyle = {
        // Add your button styles here
        //height: 60,
    };

    const imageStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '0', 
    };

    const containerStyle = {
       position: 'relative',
       zIndex: '1', 
    };

    // Function to validate email format
  const isValidEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

    const handleLogin = async () => {

        // Validate email before proceeding
    if (!isValidEmail(email)) {
        alert('Invalid email address');
      //setRegistrationStatus("Invalid email address");
      //setIsModalOpen(true);
        return;
      }

        setIsSigninLoading(true);
        setErrorMessage('');

        if (
            email  == 'Enter your email'  ||  email  == '' ||
            password == 'Enter your password' ||  password  == ''
            ) {
                setErrorMessage("Login Failed: Please enter valid credentials");
                setIsSigninLoading(false);
                return;
            }
      

        

        try {
            const response = await axios.post('https://gacsstage.zaptrance.ng/api/guest/login', {
                email,
                password,
            });
    
            if (response.data.success && response.data.data.token) {

                //alert(JSON.stringify(response.data, null, 2));

                
                // Token exists in the response, store it
                const token = response.data.data.token;
                //alert("login token: " + token)
                localStorage.setItem('token', token);
                //alert("stored token: " + localStorage.getItem('token'))
                //alert("login: " + JSON.stringify(response.data.data, null, 2));

                const institutionLogo  = response.data.data.user.institution_logo;
                localStorage.setItem('institution_logo', institutionLogo );

                const identityFile  = response.data.data.user.identity_file;
                localStorage.setItem('identity_file', identityFile );
                //alert("institution_logo: " + institutionLogo );

                const userType = response.data.data.user.type;
                //alert(JSON.stringify(response.data.data.user.type, null, 2));
                localStorage.setItem('user_type', userType);
                //alert("userType: " + userType);
                //alert(userType);

                const userId = response.data.data.user.id;
                //alert(JSON.stringify(response.data.data.user.type, null, 2));
                localStorage.setItem('user_id', userId);

                const userName = response.data.data.user.fullname;
                //alert(JSON.stringify(response.data.data.user.type, null, 2));
                localStorage.setItem('user_name', userName);

                const userEmail = response.data.data.user.email;
                //alert(JSON.stringify(response.data.data.user.type, null, 2));
                localStorage.setItem('user_email', userEmail);

                //const institutionName  = response.data.data.user.fullName;
                localStorage.setItem('institution_name', userType);//institutionName );
    

                onLogin();


                switch (userType) {
                    case "individual":
                      navigate("/guest-dashboard");
                    break;
                    default:
                        //guest
                        //alert("usertype type not handled");
                    }
                
            } else {
                setIsSigninLoading(false);

                setErrorMessage(response.data.message);

                // Handle the case where the login was not successful
                //console.error('Login failed:' + response.data.message);
            }
        } catch (error) {
            setIsSigninLoading(false);

            //Request failed with status code 401
            setErrorMessage('Login failed. Please check your credentials and try again.');

            //console.error('Login error:'+ error);
            //console.error('Login error:'+ error);
            // Handle login error, e.g., display an error message to the user
        }
    };


    return (
        <div>
            <HeaderWithHome />
            <div style={pageStyle}>
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6" style={{ backgroundColor: '#095918' }}>
                        <div className="flex flex-col justify-center p-6">
                            <p className="text-2xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-5xl font-bold text-white py-2">
                                Welcome To Generation <br />
                                And Authentication Of<br />
                                Certificates E-System<br />
                                (GACs)
                            </p>
                        </div>
                        <div className="justify-center p-6" style={containerStyle}>
                            <div className='space-y-2'>
                                <div className='flex flex-col justify-center rounded-2xl' >
                                    <form className='max-w-[500px] w-full mx-auto p-8 px-8 bg-white rounded-2xl' >
                                        <h2 className='text-2xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl text-black font-bold pb-8'>Login to GACs</h2>
                                        <div className='flex flex-col py-2'>
                                            <label>Email</label>
                                            <input style={{ backgroundColor: '#EDF2F6' }} 
                                            className='rounded-lg mt-2 p-2 focus' type='text' 
                                            placeholder={email}
                        //value={email} 
                                            onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className='flex flex-col py-2'>
                                            <label>Password</label>
                                            <input style={{ backgroundColor: '#EDF2F6' }} 
                                            className='rounded-lg mt-2 p-2 focus' type='password' 
                                            placeholder={password}
                        //value={password} 
                                            onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className='flex justify-between text-gray-400 py-2'>
                                            <p>Forgot Password? <Link className='' to='/contact'><strong>Contact Admin</strong></Link></p>
                                        </div>
                                        <div style={buttonContainerStyle} className='pt-2 text-center'>
                                            { isSigninLoading ? <LoadingScreen /> : <Link className="rounded-button" style={buttonStyle} onClick={handleLogin}><strong>Login</strong></Link> }
                                        </div>
                                        <div className='flex justify-center text-gray-400 py-2 '>
                                            <p>Having issues? <Link className='' to='/contact'><strong>Contact Admin</strong></Link></p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p style={{ color: '#990000', fontSize: '12px' }}>{errorMessage}</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <img
                            src={backRadar}
                            alt="Landing Page"
                            style={imageStyle}
                            className='absolute inset-0 mx-auto my-auto'
                        />
                    </div>
                    <FooterTwo />
                </div>
            </div>
        </div>
    );
}
