import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';
import SoftwaresIcon from '@mui/icons-material/Wysiwyg';
import BlockchainIcon from '@mui/icons-material/Https';
import HistoryIcon from '@mui/icons-material/BarChart';

import LogoutIcon from '@mui/icons-material/Logout';

import BusinessIcon from '@mui/icons-material/Business';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import { Settings } from '@mui/icons-material';



export const navData = [
    // {
    //     id: 0,
    //     icon: <HomeIcon/>,
    //     text: "Home",
    //     link: "home"
    // },
    {
        id: 1,
        icon: <AppsIcon/>,
        text: "Dashboard",
        link: "institutions-dashboard"
    },
    {
        id: 2,
        icon: <BusinessIcon/>,
        text: "Certificate",
        link: "institutions-certificate"
    },
    {
        id: 3,
        icon: <LayersIcon/>,
        text: "Accredited Programs",
        link: "institutions-accredited-programs"
    },
    {
        id: 4,
        icon: <SettingsIcon/>,
        text: "Settings",
        link: "institutions-settings"
    },
    {
        id: 5,
        icon: <LogoutIcon/>,
        text: "Logout",
        link: "logout"
    },
]