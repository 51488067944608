import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import ZaptranceSideNav from './ZaptranceSideNav';
import HeaderZaptrance from './HeaderZaptrance';

import logginImgTwo from '../../../assets/logo.png';

import icon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';

const ZaptranceProfile = () => {
    const navigate = useNavigate();

    const [institutionName, setInstitutionName] = useState('Please enter your full name');
    const [email, setEmail] = useState('Please enter your email');
    const [phoneNumber, setPhoneNumber] = useState('Please enter your phone number');
    const [institutionType, setInstitutionType] = useState('Polytechnic');

    const [address, setAddress] = useState('Institution Address');
    const [state, setState] = useState('Please enter your location');
    const [postalCode, setPostalCode] = useState('33902');

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'right', // Center horizontally
        marginTop: '20px', // Add margin for spacing
    };

    const buttonStyle = {
        // Add your button styles here
        //height: 60,
    };


    let token = localStorage.getItem('token');
    useEffect(() => {
      if (!token) {
        // Redirect to the home page if the token is null
        navigate('/');
      } else {
        // If the user is authenticated, call the handleData function
        //handleData();
      }
    }, [navigate]);


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setPhoneNumber(value);
      };

    return (
        <div className="content flex-grow">
                <div className="inner-content">
                    <HeaderZaptrance />
                    <div className=' p-4' style={{backgroundColor: '#F6F7FB'}}>
                    <div className='text-black p-4 flex text-xl'>
                            <div><LayersIcon className='mr-4'/></div>Profile
                        </div>
                        
                        <img className="w-112 h-40 " src={logginImgTwo} alt="" />


                        <div className="flex justify-between">
                            <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" style={{backgroundColor: '#ffffff'}}> 

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                                <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" > 
                                    <div className='flex flex-col py-2'>
                                        <label>Institution Name</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={institutionName}
                                        //value={institutionName} 
                                        onChange={(e) => setInstitutionName(e.target.value)} />
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label>Email</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={email}
                                        //value={email} 
                                        onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label>Phone Number</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='number'
                                        pattern='[0-9]*'
                                        placeholder={phoneNumber}
                                        value={phoneNumber} 
                                        onChange={handlePhoneNumberChange} />
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label>Institution Type</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={institutionType}
                                        //value={institutionType} 
                                        onChange={(e) => setInstitutionType(e.target.value)} />
                                    </div>
                                </div>
                                <div className="rounded-lg p-4 flex-1 m-2 flex-col text-black" > 
                                <div className='flex flex-col py-2'>
                                        <label>Address</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={address}
                                        //value={address} 
                                        onChange={(e) => setAddress(e.target.value)} />
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label>State</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={state}
                                        //value={state} 
                                        onChange={(e) => setState(e.target.value)} />
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label>Postal Code</label>
                                        <input style={{backgroundColor: '#EDF2F6'}}
                                        className='rounded-l mt-2 p-2 focus' 
                                        type='text' 
                                        placeholder={postalCode}
                                        //value={postalCode} 
                                        onChange={(e) => setPostalCode(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div style={buttonContainerStyle} className='pt-8'>
                                <button className="rounded-button" style={buttonStyle}>Update Info</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default ZaptranceProfile;
